import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useApp } from "../../context/AppContext";
import Typography from "../Typography";
import { TickerButtonProps } from "./TickerButton.types";

const MarqueeWrapper = styled.div`
  position: absolute;
  width: 100%;
  visibility: hidden;
`

const StyledTypography = styled(Typography)`
  display: block;
  text-align: center;
`

const StyledTypographyMarquee = styled(Typography)`
  margin-right: 0.25em;
`

const StyledTypographyNoHover = styled(Typography)`
  display: block;
`

const TickerButtonWrapper = styled(Link) <TickerButtonProps | any>`
  display: inline-block;
  border: 0.1rem solid;
  border-color: currentColor;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  color: currentColor;
  transform: translateZ(0);
  outline: none;
  padding: ${(props) => props.variant === "small" ? "0.6rem 4rem" : "0.6rem 5rem"};
  
  @media screen and (min-width: 1024px){
    padding: ${(props) => props.variant === "small" ? "0.8rem 6rem" : "1rem 8rem"};
  }

  .has-hover & {
    ${MarqueeWrapper} {
      transform: translateZ(0);
    }

    &:hover {
      ${MarqueeWrapper}{
        visibility: visible;
      }
  
      ${StyledTypography}{
        visibility: hidden;
      }
    }
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
`;

const TickerButton = ({
  className,
  onClick,
  text = "View project",
  color = "currentColor",
  useMarquee = true,
  href,
  target = "_self",
  variant = "medium",
  as = Link,
}: TickerButtonProps) => {

  const { state } = useApp();
  const { isMobile } = state;

  const textSize = isMobile ? "h9" : "h8";

  return (
    <TickerButtonWrapper
      className={className}
      onClick={onClick}
      color={color}
      to={href}
      as={as}
      $useMarquee={useMarquee}
      target={target}
      variant={variant}
    >
      <TextWrapper>
        {useMarquee &&
          <MarqueeWrapper>
            <Marquee play={true} gradient={false} speed={75}>
              <StyledTypographyMarquee variant={textSize} color={color}>
                {`${text} ${text} ${text}`}
              </StyledTypographyMarquee>
            </Marquee>
          </MarqueeWrapper>
        }
      </TextWrapper>
      {useMarquee && <StyledTypography variant={textSize} color={color} alignment="center">
        {text}
      </StyledTypography>}
      {!useMarquee && <StyledTypographyNoHover variant={textSize} color={color}>
        {text}
      </StyledTypographyNoHover>}
    </TickerButtonWrapper>
  );
};

export default TickerButton;
