import { asText } from "@prismicio/helpers";
import { ReactNode } from "react";
import Carousel from "../components/Carousel/Carousel";
import { SocialTypes } from "../components/Carousel/Carousel.types";
import MainDrop from "../components/layout/MainDrop";
// import SecondaryDrop from "../components/layout/SecondaryDrop";
import SplitTextA from "../components/layout/SplitTextA";
import SplitTextB from "../components/layout/SplitTextB";
import PrimaryHero from "../components/PrimaryHero";
import MoreProjects from "../components/MoreProjects";
import Divider from "../components/layout/Divider";
import Product360 from "../components/Product360";
import { Theme } from "../components/ThemeWrapper/ThemeWrapper.types";
import { getUrlFromPrimicLink } from "./Helpers";
import TextOnly from "../components/layout/TextOnly";
import SecondaryHero from "../components/SecondaryHero";
import OtherDrops from "../components/OtherDrops";
import { Product360Type } from "../components/Product360/Product360.types";
import { PrimaryHeroTypes } from "../components/PrimaryHero/PrimaryHero.types";

interface Props {
  // TODO: type this correctly
  components: any
}

export function ComponentMapper({ components }: Props) {

  function getComponent(component: any, index: number): ReactNode {
    try {
      switch (component.slice_type) {
        case 'primaryhero':
          return <PrimaryHero
            key={component.slice_type + index}
            type={component.primary.video.url ? PrimaryHeroTypes.VIDEO : PrimaryHeroTypes.IMAGE}
            videoUrl={component.primary.video.url}
            imageUrl={component.primary.image.url}
            button={{ label: component.primary.button_label }}
            backgroundVideo={{
              url: '/video/buffalo-header-low.mp4',
              videoDimensions: {
                width: 1569,
                height: 1080,
              }
            }}
          />
        case 'secondaryhero':
          return <SecondaryHero
            key={component.slice_type + index}
            images={[component.primary.image_left, component.primary.image_right]}
            title={asText(component.primary.title1) || ''}
            launchDate={new Date(component.primary.launch_date)}
            logo={component.primary.collaboration_logo}
            links={component.items.map(
              (
                { product_link_label, product_link_anchor }:
                  { product_link_label: string, product_link_anchor: string }
              ) => (
                { label: product_link_label, url: product_link_anchor }
              )
            )}
            showCountdown={component.primary.show_countdown}
            showRibbon={component.primary.show_ribbon}
            overlayTextColor={component.primary.overlay_text_color}
          />
        case 'split_text_a':
          return <SplitTextA
            key={component.slice_type + index}
            theme={component.primary.theme ? 'light' : 'dark'}
            image={component.primary.image}
            title={asText(component.primary.title1) || undefined}
            body={component.primary.content.map((p: { text: string }) => p.text)}
          />
        case 'split_text_b':
          return <SplitTextB
            key={component.slice_type + index}
            theme={component.primary.theme ? 'light' : 'dark'}
            image={component.primary.image}
            title={asText(component.primary.title1) || undefined}
            body={component.primary.content.map((p: { text: string }) => p.text)}
          />
        case 'text_only':
          return <TextOnly
            key={component.slice_type + index}
            theme={component.primary.theme ? Theme.LIGHT : Theme.DARK}
            title={asText(component.primary.title1) || undefined}
            body={component.primary.content.map((p: { text: string }) => p.text)}
          />
        case "main_drop":
          return <MainDrop
            key={component.slice_type + index}
            hero={{
              images: [component.primary.image_left, component.primary.image_right],
              collaboratorName: asText(component.primary.title1) || '',
              showHeader: true,
              showRibbon: component.primary.show_ribbon
            }}
            countdown={{
              visible: component.primary.show_countdown,
              date: component.primary.launch_date ? new Date(component.primary.launch_date) : undefined
            }}
            content={{
              body: component.primary?.content.map((p: { text: string }) => p.text) || [],
              images: [
                component.primary.content_image_left,
                component.primary.content_image_right,
              ],
              link: {
                label: component.primary.cta_label,
                url: getUrlFromPrimicLink(component.primary.cta_url)
              }
            }}
          />
        case "other_drops":
          return <OtherDrops
            key={component.slice_type + index}
            button={{ label: component.primary.button_label }}
            drops={component.items.map(((item: any) => {
              return {
                name: asText(item.drop.data?.sd_title) || asText(item.drop.data?.title),
                image: item.drop?.data?.sd_image,
                button: {
                  label: item.drop?.data?.sd_link_label,
                  url: `/drop/${item.drop.slug}`,
                },
                slider: {
                  colorTop: item.drop?.data?.sd_color_top,
                  colorTopAlpha: item.drop?.data?.sd_color_top_alpha,
                  colorBottom: item.drop?.data?.sd_color_bottom,
                  colorBottomAlpha: item.drop?.data?.sd_color_bottom_alpha,
                  products: item.drop?.data?.sd_products?.map((product: any) => {
                    return {
                      name: asText(product.product.data?.name),
                      price: product.product.data?.price,
                      image: product.product.data?.secondary_drop_image,
                      soldOut: product.product.data?.sold_out,
                    }
                  }) || []
                }
              }
            }))}
          />
        case "slider":
          const socials = [
            { ...component.primary.social_link_facebook, type: SocialTypes.FACEBOOK },
            { ...component.primary.social_link_instagram, type: SocialTypes.INSTAGRAM },
            { ...component.primary.social_link_linkedin, type: SocialTypes.LINKEDIN },
            { ...component.primary.social_link_tik_tok, type: SocialTypes.TIKTOK },
            { ...component.primary.social_link_youtube, type: SocialTypes.YOUTUBE }
          ].filter(social => social.url);

          return <Carousel
            key={component.slice_type + index}
            title={asText(component.primary.title1) || ''}
            items={component.items}
            footer={{
              title: asText(component.primary.subtitle) || '',
              body: component.primary?.content.map((p: { text: string }) => p.text) || [],
              socials,
              link: {
                label: component.primary.link_label,
                url: component.primary.link_url.url,
              }
            }}
          />
        case 'more_projects':
          const projects = component.items.map((item: any) => {
            return {
              text: item.drop_name,
              image: item.image,
              direction: item.direction ? 'right' : 'left',
              url: `/#/drop/${item.drop.slug}`
            }
          }) || [];

          return <MoreProjects
            key={component.slice_type + index}
            data={projects}
          />
        case 'divider':
          return <Divider
            key={component.slice_type + index}
            labelText={asText(component.primary.title1) || 'BUFFALO SOURCE'}
          />
        case 'product_360':
          const product: Product360Type = { uid: '' };

          if (component.primary.product.id) {
            const { name, color, price, sold_out, images } = component.primary.product.data;
            product.uid = component.primary.product.uid;
            product.title = asText(name) || '';
            product.color = color;
            product.price = price;
            product.soldOut = sold_out;
            product.images = images.map((item: any) => item.image)
          }

          return <Product360
            key={component.slice_type + index}
            anchor={component.primary.product_slug}
            soldOutLabel={component.primary.sold_out_label || "Sold out"}
            background={{
              colorTop: component.primary.color_top || "#2E323D",
              colorTopAlpha: component.primary.color_top_alpha / 100 || 1,
              colorBottom: component.primary.color_bottom || "#AB99A0",
              colorBottomAlpha: component.primary.color_bottom_alpha / 100 || 1,
            }}
            link={{
              label: component.primary?.link_label || "Shop now",
              labelMobile: component.primary?.link_label_mobile || "Shop",
              url: component.primary?.product?.data?.link_url?.url || "https://buffalo-boots.com/#/fallback",
            }}
            product={product}
          />
        default:
          return null;
      }

    } catch (e: any) {
      console.error(`Something went wrong rendering :: ${component.slice_type} ::`, e.message)
    }
  }

  return (
    <>
      {components.map((component: any, i: number) => getComponent(component, i))}
    </>

  );
};