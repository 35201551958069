import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";

import { SecondaryDropProps } from "./SecondaryDrop.types";
import Typography from "../../Typography";
import Image from "../../Image";
import TickerButton from "../../TickerButton";
import Slider from "../../Slider";
import { useApp } from "../../../context/AppContext";
import ThemeWrapper from "../../ThemeWrapper";
import { Theme } from "../../ThemeWrapper/ThemeWrapper.types";
import { Link } from "react-router-dom";

const SecondaryDropEl = styled(ThemeWrapper)``;

const StyledMarquee = styled(Marquee)`
  height: 8rem;
  overflow-y: hidden;
  border-top: 0.1rem solid;
  border-bottom: 0.1rem solid;
  border-color: currentColor;
`;

const StyledMobileTitle = styled(Typography)`
  border: 1px solid black;
  border-left: none;
  border-right: none;
  padding: 1rem 0;
`;

const CopyItem = styled.span`
  /* margin: 0 1rem; */
  text-transform: uppercase;

  @media screen and (min-width: 1024px) {
    margin: 0 1rem;
  }
`;

const Content = styled.div.attrs((props: { inverse: boolean }) => props)`
  display: flex;
  border-bottom: 1px solid black;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    flex-direction: ${(props) => (props.inverse ? "row-reverse" : "row")};
    align-items: unset;
    flex-wrap: wrap;
  }
`;

const ContentProject = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex: 0 0 65%;
  }
`;

const ContentSlider = styled.div`
  width: 100%;
  height: 36rem;

  @media screen and (min-width: 1024px) {
    height: unset;
    flex: 0 0 35%;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
`;

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 6.2rem;
  border-top: 1px solid black;

  @media screen and (min-width: 1024px) {
    height: 8rem;
  }
`;

const StyledProjectButton = styled(TickerButton)`
  color: currentColor;
`;

export default function SecondaryDrop({
  name,
  image,
  button,
  inverse,
  slider,
  theme = Theme.DARK,
}: SecondaryDropProps) {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  const elRef = useRef<HTMLDivElement>(null);
  const copyLineRef = useRef<HTMLDivElement>(null);
  const [repeateCount] = useState(2);

  useEffect(() => {
    if (!elRef.current || !copyLineRef.current) return;

    // const { width } = elRef.current.getBoundingClientRect();
    // const { width: CopyWidth } = copyLineRef.current.getBoundingClientRect();
    // console.log(width, CopyWidth);
  }, []);

  return (
    <SecondaryDropEl ref={elRef} theme={theme}>
      {!isMobile && (
        <StyledMarquee
          gradient={false}
          speed={75}
          direction={inverse ? "left" : "right"}
        >
          <Typography variant="h2" component={"h3"}>
            {/* TODO: optionally make the repeatcount dynamic based on width of the title  */}
            {/* NOTE: refs don't work whithin the Marquee component for some reason  */}
            {[...Array(repeateCount)].map((n, i) => (
              <Fragment key={i}>
                <CopyItem>Buffalo Source</CopyItem>
                <CopyItem>X</CopyItem>
                <CopyItem>{name}</CopyItem>
              </Fragment>
            ))}
          </Typography>
        </StyledMarquee>
      )}

      {isMobile && (
        <StyledMobileTitle variant="h5">
          <CopyItem>Buffalo Source X</CopyItem>
          <br />
          <CopyItem>{name}</CopyItem>
        </StyledMobileTitle>
      )}

      <Content inverse={inverse}>
        <ContentProject>
          <ImageWrapper>
            <Link to={button.url}>
              <StyledImage src={image?.url} alt={image?.alt || ""} />
            </Link>
          </ImageWrapper>
        </ContentProject>
        <ContentSlider>
          <Slider inverse={inverse} {...slider} />
        </ContentSlider>
        <Footer>
          <StyledProjectButton
            text={button.label}
            href={button.url}
            variant="small"
          />
        </Footer>
      </Content>
    </SecondaryDropEl>
  );
}
