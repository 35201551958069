import styled from "styled-components";
import { CarouselFooterProps, SocialTypes } from "./Carousel.types";
import Typography from "../Typography";
import NewTab from "../Icons/NewTab";
import Instagram from "../Icons/Instagram";
import Facebook from "../Icons/Facebook";
import Youtube from "../Icons/Youtube";
import Linkedin from "../Icons/Linkedin";
import TikTok from "../Icons/TikTok";
import Spacer from "../layout/Spacer";
import { useApp } from "../../context/AppContext";

const Footer = styled.div`
  position: relative;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`

const StyleTitleTypography = styled(Typography)`
  
`

const StyledBodyTypography = styled(Typography)`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`

const ColumnLeft = styled.div`
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
  `

const ColumnRight = styled.div`
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  display: inline-flex;
`

const SocialList = styled.ul`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  list-style: none;
  margin: 0;
  padding: 0;
`

const SocialListItem = styled.li`
  margin: 0 2rem 0 0;
`

const CarouselFooter = ({ className, title, body, link, socials }: CarouselFooterProps) => {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  function getSocialIconForType(type: SocialTypes) {
    switch (type) {
      case SocialTypes.FACEBOOK:
        return <Facebook width="1.3rem" height="2.5rem" />
      case SocialTypes.INSTAGRAM:
        return <Instagram width="2.2rem" height="2.2rem" />
      case SocialTypes.LINKEDIN:
        return <Linkedin width="2.3rem" height="2.4rem" />
      case SocialTypes.TIKTOK:
        return <TikTok width="2.3rem" height="2.4rem" />
      case SocialTypes.YOUTUBE:
        return <Youtube width="2.3rem" height="1.6rem" />
      default:
        return;
    }
  }

  return (
    <Footer className={className}>
      <ColumnLeft>
        <div>
          {title && <StyleTitleTypography variant={isMobile ? "h7" : "h4"}>{title}</StyleTitleTypography>}
          <Spacer size={6} />
          {body && isMobile &&
            <>
              {body && isMobile && body.map((p, i) =>
                (<StyledBodyTypography variant="body4" key={i}>{p}</StyledBodyTypography>)
              )}
              <Spacer size={6} />
            </>
          }
        </div>
        <div>
          {link?.label && link?.url &&
            <StyledLink href={link.url} target="_blank" rel="noreferrer">
              <Typography variant={isMobile ? "body3" : "body2"}>
                {link.label}
              </Typography>
              <NewTab
                width={isMobile ? "1.4rem" : "1.6rem"}
                height={isMobile ? "1.4rem" : "1.6rem"}
                style={{
                  marginLeft: isMobile ? "1.4rem" : "1.6rem",
                  marginTop: "0.35rem",
                }}
              />
            </StyledLink>
          }
          <Spacer size={isMobile ? 2 : 4} />
          <SocialList>
            {socials?.map((social, i) => (
              <SocialListItem key={i}>
                <a href={social.url}>
                  {getSocialIconForType(social.type)}
                </a>
              </SocialListItem>
            ))}
          </SocialList>
        </div>
      </ColumnLeft>
      <ColumnRight>
        {body && !isMobile && body.map((p, i) =>
          (<StyledBodyTypography key={i} variant="body2">{p}</StyledBodyTypography>)
        )}
      </ColumnRight>
    </Footer>
  )
}

export default CarouselFooter;