import { useContext, useReducer, createContext, ReactNode, Dispatch, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import IsMobile from "../utils/IsMobile";
import IsTouch from "../utils/IsTouch";

export enum Actions {
  SET_IS_MOBILE = "SET_IS_MOBILE",
  SET_IS_TOUCH = "SET_IS_TOUCH",
  SET_IS_DEBUG = "SET_IS_DEBUG",
}

interface AppStateInterface {
  isMobile: boolean;
  isTouch: boolean;
  isDebug: boolean;
}

interface AppContextInterface {
  state: AppStateInterface;
  dispatch: Dispatch<ActionInterface>;
}
interface ActionInterface {
  type: Actions;
  payload: any;
}

const initialState = {
  isMobile: false,
  isTouch: false,
  isDebug: false,
};

export const AppContext = createContext<AppContextInterface>({
  state: initialState,
  dispatch: () => null,
});

const { Provider } = AppContext;

const reducer = (state: AppStateInterface, action: ActionInterface): AppStateInterface => {
  switch (action.type) {
    case Actions.SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case Actions.SET_IS_TOUCH:
      return { ...state, isTouch: action.payload };
    case Actions.SET_IS_DEBUG:
      return { ...state, isDebug: action.payload };
    default:
      return state;
  }
};

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const startState = {
    ...initialState,
  };
  const [state, dispatch] = useReducer(reducer, startState);

  function updateIsMobile(isMobile: boolean) {
    dispatch({ type: Actions.SET_IS_MOBILE, payload: isMobile })
  }

  function updateIsTouch(isTouch: boolean) {
    dispatch({ type: Actions.SET_IS_TOUCH, payload: isTouch })
  }

  function updateDebugState(isDebug: boolean) {
    dispatch({ type: Actions.SET_IS_DEBUG, payload: isDebug })
  }

  useEffect(() => {
    const isMobile = IsMobile();
    updateIsMobile(isMobile);

    function onResize() {
      const isMobile = IsMobile();
      const isTouch = IsTouch();

      if (isMobile !== state.isMobile) {
        updateIsMobile(isMobile);
      }

      if (isTouch !== state.isTouch) {
        updateIsTouch(isTouch);
      }
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [state.isMobile, state.isTouch]);

  useEffect(() => {
    const isDebug = query.get('debug') === 'true'
    if (isDebug) updateDebugState(isDebug);
  }, [query]);

  useEffect(() => {
    if (state.isTouch) {
      document.documentElement.classList.remove('has-hover');
    } else {
      document.documentElement.classList.add('has-hover');
    }

  }, [state.isTouch])


  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useApp = () => useContext(AppContext);
