import "@dotlottie/player-component";

import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import { useApp } from "../../context/AppContext";
import { hexToRgb } from "../../utils/HexToRgb";
import TickerButton from "../TickerButton";
import Typography from "../Typography";
import { Product360Props } from "./Product360.types";

import ThemeWrapper from "../ThemeWrapper";
import ParallaxWrapper from "../ParallaxWrapper";
import useImageLoader from "../../hooks/useImageLoader";
import useWindowSize from "../../hooks/useWindowSize";

gsap.registerPlugin(Draggable);

const LOTTIE = {
  mobile: "/drag-animation-mobile.json",
  desktop: "/drag-animation.json",
};

const Product360El = styled(ThemeWrapper)`
  position: relative;
  user-select: none;

  &:after {
    content: "";
    display: block;
    padding-bottom: 162.5%;
  }

  @media screen and (min-width: 1024px) {
    &:after {
      padding-bottom: 57.5%;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundTop = styled.span`
  flex: 0 0 50%;
  display: block;
  mix-blend-mode: multiply;

  @media screen and (min-width: 1024px) {
    flex: 0 0 66.66%;
  }
`;

const BackgroundBottom = styled.span`
  flex: 1 1 100%;
  display: block;

  background-color: rgba(100, 100, 100, 0.1);
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3rem 3rem;

  @media screen and (min-width: 1024px) {
    padding: 6rem 8rem;
  }
`;

const ProductHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
`;

const ProductHeaderLabelWrapper = styled.div`
  flex: 0 0 50%;
`;

const ProductHeaderLabel = styled(Typography)``;

const LabelBracket = styled.span`
  &:first-child {
    margin: 0 1.5rem 0 0;
  }

  &:last-child {
    margin: 0 0 0 1.5rem;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
  height: 64%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 6rem auto;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    margin: auto;
    width: 60%;
    height: 72%;
  }
`;

const Image = styled.img`
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 60%;

  &[src=""] {
    visibility: hidden;
  }

  @media screen and (min-width: 1024px) {
    object-position: 50% 80%;
  }
`;

const LottieWrapper = styled.div`
  flex: 0 0 100%;

  @media screen and (min-width: 1024px) {
    flex: 0 0 100%;
  }
`;

const Product = styled(Typography)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.15em;
    margin-bottom: 0;
  }
`;

const ProductTitle = styled.span`
  padding: 0.5rem 0;
  @media screen and (min-width: 1024px) {
    padding: 1.1rem 0 1.4rem;
    flex: 0 0 33.33%;
  }
`;

const ProductColor = styled.span`
  padding: 0.5rem 0;

  @media screen and (max-width: 1023px) {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 33.33%;
    text-align: center;
    padding: 1.1rem 0 1.4rem;
  }
`;

const ProductPrice = styled.span`
  padding: 0.5rem 0;
  @media screen and (min-width: 1024px) {
    flex: 0 0 33.33%;
    text-align: right;
    padding: 1.1rem 0 1.4rem;
  }
`;

function Product360({
  product,
  anchor,
  background = {
    colorTop: "#434343",
    colorTopAlpha: 100,
    colorBottom: "AEAEAE",
    colorBottomAlpha: 100,
  },
  link,
  soldOutLabel,
}: Product360Props) {
  // CONTEXT
  const { state } = useApp();
  const { isMobile, isDebug } = state;

  // REFS
  const elRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const lottieWrapperRef = useRef<HTMLDivElement>(null);
  const imagesRef = useRef<HTMLImageElement[]>([]);
  const offsetX = useRef(0);
  const activeIndex = useRef(0);
  const draggableRef = useRef<Draggable>();
  const userHasDragged = useRef(false);
  const hasMultipleImages = useRef(
    (product?.images?.length && product?.images?.length > 1) || false
  );

  // STATE
  const [imagesLoaded, setImagesLoaded] =
    useState<{ id: string; images: string[] }>();

  // HOOKS
  const [images, loadImages] = useImageLoader();
  const { width, height } = useWindowSize();

  // FRAMER
  const isInView = useInView(elRef, {
    // margin: "0px 100px -50px 0px",
    once: true,
  });

  // COLORS
  const ctRGBA = hexToRgb(background.colorTop as string);
  const cbRGBA = hexToRgb(background.colorBottom as string);
  const { colorTopAlpha, colorBottomAlpha } = background;

  // HOOKS
  useEffect(() => {
    if (!hasMultipleImages.current) return;
    function handleDrag() {
      if (!draggableRef.current) return;

      offsetX.current += draggableRef.current.deltaX;

      const distance = isMobile ? 300 : 400;
      const refSize = isMobile ? 375 : 1440;
      const maxSize = (distance * width) / refSize;
      const stepSize = maxSize / imagesRef.current.length;
      offsetX.current = (offsetX.current + maxSize) % maxSize;

      activeIndex.current = Math.floor(offsetX.current / stepSize);

      for (let i = 0; i < imagesRef.current.length; i++) {
        const image = imagesRef.current[i];
        image.style.visibility =
          i === activeIndex.current ? "visible" : "hidden";
      }

      if (!userHasDragged.current) {
        userHasDragged.current = true;
        gsap.to(lottieWrapperRef.current, {
          y: -40,
          autoAlpha: 0,
          duration: 0.4,
          ease: "Power2.easeInOut",
        });
      }
    }

    const handleDrageEnd = () => {
      if (!draggableRef.current) return;

      let timer;

      timer = null;
      if (timer) {
        clearTimeout(timer); //cancel the previous timer.
        timer = null;
      }

      timer = setTimeout(() => {
        userHasDragged.current = false;
        gsap.to(lottieWrapperRef.current, {
          y: -40,
          autoAlpha: 1,
          duration: 0.4,
          ease: "Power2.easeInOut",
        });
      }, 2000);
    };

    var proxy = document.createElement("div");

    // @ts-ignore: next-line;
    draggableRef.current = Draggable.create(proxy, {
      type: "x",
      trigger: imageContainerRef.current,
      onDrag: handleDrag,
      onDragEnd: handleDrageEnd,
    })[0];

    const d = draggableRef.current;

    return () => {
      if (d) d?.kill();
    };
  }, [width, height, isMobile]);

  useEffect(() => {
    if (!imagesLoaded && hasMultipleImages.current) {
      const productImages = product.images?.map(
        (productImage) => productImage.url
      );
      if (productImages) {
        loadImages({ id: product.uid, images: productImages });
      }
    }
  }, [imagesLoaded, loadImages, product]);

  useEffect(() => {
    const batchImages = images.find((image) => image.id === product.uid);
    if (batchImages && isInView) {
      setImagesLoaded(batchImages);
    }
  }, [isInView, images, product]);

  return (
    <Product360El ref={elRef} data-anchor={anchor}>
      <Background>
        <BackgroundTop
          style={{
            background: `linear-gradient(180deg, rgba(${ctRGBA?.r}, ${ctRGBA?.g}, ${ctRGBA?.b}, ${colorTopAlpha}) 0%, 
              rgba(${cbRGBA?.r}, ${cbRGBA?.g}, ${cbRGBA?.b}, ${colorBottomAlpha}) 100%)`,
          }}
        />
        <BackgroundBottom />
      </Background>
      <Content>
        <ImageContainer
          ref={imageContainerRef}
          style={isDebug ? { border: "1px solid red" } : {}}
        >
          {/* NOTE: show first images while loading other images */}
          {product?.images?.length && product.images[0] && (
            <Image
              ref={(ref: HTMLImageElement) => (imagesRef.current[0] = ref)}
              src={product.images[0].url}
              alt={product.images[0].alt || ""}
              draggable="false"
            />
          )}
          {/* NOTE: worker loaded images */}
          {imagesLoaded &&
            imagesLoaded.images.map((image, i) => {
              if (i === 0) return null;
              return (
                <Image
                  key={i}
                  ref={(ref: HTMLImageElement) => (imagesRef.current[i] = ref)}
                  src={image}
                  style={{ visibility: "hidden" }}
                  draggable="false"
                />
              );
            })}
          {hasMultipleImages.current && (
            <LottieWrapper ref={lottieWrapperRef}>
              <ParallaxWrapper
                positions={{
                  start: -50,
                  startMobile: 20,
                  end: 50,
                  endMobile: 0,
                }}
                offset={["start 70%", "end 30%"]}
              >
                <dotlottie-player
                  src={isMobile ? LOTTIE.mobile : LOTTIE.desktop}
                  autoplay
                  loop
                  mode="normal"
                  style={{ width: "100%", height: "100%" }}
                />
              </ParallaxWrapper>
            </LottieWrapper>
          )}
        </ImageContainer>
        <ProductHeader>
          <ProductHeaderLabelWrapper>
            {product?.soldOut && (
              <ProductHeaderLabel variant={isMobile ? "h9" : "h8"}>
                <LabelBracket>[</LabelBracket>
                {soldOutLabel}
                <LabelBracket>]</LabelBracket>
              </ProductHeaderLabel>
            )}
          </ProductHeaderLabelWrapper>
          <TickerButton
            text={isMobile ? link?.labelMobile : link?.label}
            href={link?.url}
            target="_blank"
            variant="small"
          />
        </ProductHeader>
        <Product color="black" variant={isMobile ? "body4" : "body1"}>
          <ProductTitle>{product?.title}</ProductTitle>
          <ProductColor>{product?.color}</ProductColor>
          <ProductPrice>{product?.price} EUR</ProductPrice>
        </Product>
      </Content>
    </Product360El>
  );
}

export default Product360;
