type dimensions = {
  widt: number;
  height: number;
};

export enum PagesType {
  DROP = "droppage",
  HOME = "homepage",
}

export interface PrismicImage {
  url: string;
  alt: string | null;
  dimenstions?: dimensions;
  copyright?: string | null;
  mobile?: {
    url: string;
  } | null;
}

export interface PrismicLink {
  isBroken: boolean;
  lang: string;
  slug: string;
  type: PagesType;
  uid: string;
}
