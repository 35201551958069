import * as React from "react";

const Youtube = ({
  width = 24,
  height = 17,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4858 2.9398C23.2132 1.91332 22.4099 1.1049 21.3901 0.830556C19.5416 0.332031 12.1292 0.332031 12.1292 0.332031C12.1292 0.332031 4.71688 0.332031 2.86832 0.830556C1.84848 1.10495 1.04527 1.91332 0.772656 2.9398C0.277344 4.80035 0.277344 8.6822 0.277344 8.6822C0.277344 8.6822 0.277344 12.5641 0.772656 14.4246C1.04527 15.4511 1.84848 16.2258 2.86832 16.5002C4.71688 16.9987 12.1292 16.9987 12.1292 16.9987C12.1292 16.9987 19.5415 16.9987 21.3901 16.5002C22.4099 16.2258 23.2132 15.4511 23.4858 14.4246C23.9811 12.5641 23.9811 8.6822 23.9811 8.6822C23.9811 8.6822 23.9811 4.80035 23.4858 2.9398ZM9.70495 12.2066V5.15777L15.9002 8.68229L9.70495 12.2066Z"
        fill={color}
      />
    </svg>
  );
};

export default Youtube;
