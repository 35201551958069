import * as React from "react";

const NewTab = ({
  width = 18,
  height = 18,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H8.475V1.5H1.5V16.5H16.5V9.525H18V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18H1.5ZM6.55 12.525L5.5 11.45L15.45 1.5H9.975V0H18V8.025H16.5V2.575L6.55 12.525Z"
        fill={color}
      />
    </svg>
  );
};

export default NewTab;
