import React from "react";

import styled from "styled-components";
import ProjectTape from "../ProjectTape";
import ThemeWrapper from "../ThemeWrapper";
import { MoreProjectsProps } from "./MoreProjects.types";

const MoreProjectsWrapper = styled(ThemeWrapper)`
  display: flex;
  flex-direction: column;

  background-color: var(--color-grey-light);
`;

const StyledProjectTape = styled(ProjectTape)`
  margin-bottom: 12rem;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
`

const MoreProjects = ({ data }: MoreProjectsProps) => {
  return (
    <MoreProjectsWrapper>
      {data?.map((projectTape, index) => (<StyledProjectTape key={`project-tape-${index}`} {...projectTape} />))}
    </MoreProjectsWrapper>
  );
};

export default MoreProjects;
