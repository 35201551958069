import React from 'react'
import styled from 'styled-components'
import { PageBackgroundProps } from './PageBackground.types'

const PageBackgroundEl = styled.div`
  overflow: hidden; // Prevent collapsing margins
  background-image: url(/images/gradient-background-mobile.jpg);
  background-size: 100%;

  @media screen and (min-width: 1024px) {
    background-image: url(/images/gradient-background.jpg);
  }
`

export default function PageBackground({ children }: PageBackgroundProps) {
  return (
    <PageBackgroundEl>{children}</PageBackgroundEl>
  )
}
