import { GLSL, Shaders } from "gl-react";

const shaders = Shaders.create({
  helloGL: {
    frag: GLSL`
      precision highp float;
      varying vec2 uv;
      void main() {
        gl_FragColor = vec4(uv.x, uv.y, 0.5, 1.0);
      }
    `
  },
  video: {
    frag: GLSL`
      precision highp float;
      varying vec2 uv;
      uniform sampler2D texture;

      void main() {
        // Mask Texture;
        float maskX = uv.x * 0.5 + 0.5;
        vec4 maskColor = texture2D(texture, vec2(maskX, uv.y));
        float maskAlpha = maskColor.r;

        // Texture to Render;
        float textureX = uv.x * 0.5;
        vec4 textureColor = texture2D(texture, vec2(textureX, uv.y));

        // Final Color with mask Alpha;
        gl_FragColor = vec4(textureColor.rgb, maskAlpha);
      }
    `
  }
})

export default shaders