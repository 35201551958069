import { useContext, useReducer, createContext, ReactNode, Dispatch, useEffect, useRef, useCallback } from "react";

export enum ImageWorkerActions {
  LOAD_IMAGES = "LOAD_IMAGES",
  ADD_IMAGE = "ADD_IMAGE",
}

export type BatchImage = {
  id: string;
  images: string[];
}

interface ImageWorkerStateInterface {
  images: {
    id: string;
    images: string[]
  }[],
  requestedImages: BatchImage[]
}

interface ImageWorkerContextInterface {
  state: ImageWorkerStateInterface;
  dispatch: Dispatch<ActionInterface>;
}
interface ActionInterface {
  type: ImageWorkerActions;
  payload: any;
}

const initialState = {
  images: [],
  requestedImages: []
};

export const ImageWorkerContext = createContext<ImageWorkerContextInterface>({
  state: initialState,
  dispatch: () => null,
});

const { Provider } = ImageWorkerContext;

const reducer = (state: ImageWorkerStateInterface, action: ActionInterface): ImageWorkerStateInterface => {
  switch (action.type) {
    case ImageWorkerActions.LOAD_IMAGES:
      if (state.requestedImages.find((reqImage) => reqImage.id === action.payload.id)) return state;
      return { ...state, requestedImages: [...state.requestedImages, action.payload] };
    case ImageWorkerActions.ADD_IMAGE:
      return { ...state, images: [...state.images, action.payload] };
    default:
      return state;
  }
};

export const ImageWorkerProvider = ({ children }: { children: ReactNode }) => {
  const startState = {
    ...initialState,
  };
  const [state, dispatch] = useReducer(reducer, startState);

  // REFS
  const worker = useRef<Worker | null>(null);
  const activeLoadingImages = useRef<string[]>([]);

  // METHODS
  function checkLoading(id: string): boolean {
    const isLoading = !!activeLoadingImages.current.find(imageId => imageId === id)
    return isLoading;
  };

  const updateImages = useCallback(async (id: string, blobsArray: string[]) => {
    dispatch({ type: ImageWorkerActions.ADD_IMAGE, payload: { id, images: blobsArray } });
  }, []);


  const loadImages = useCallback(({ id, images }: BatchImage) => {
    const isLoaded = state.images.find(imageBatch => imageBatch.id! === id);
    const isLoading = checkLoading(id);

    if (!isLoaded && !isLoading) {
      activeLoadingImages.current.push(id);
      worker.current?.postMessage({ id, images });
    }
  }, [state.images]);


  // EVENT HANDLERS
  const handleWorkerMessage = useCallback((e: any) => {
    activeLoadingImages.current = activeLoadingImages.current.filter((v, i, arr) => {
      if (v === e.data.id) {
        activeLoadingImages.current.splice(i, 1);
        return true;
      }
      return false;
    });

    if (e.data.id && e.data.images) {
      updateImages(e.data.id, e.data.images);
    } else {
      console.log('NO IMAGES LOADED FOR ID :: ', e.data.id);
    }
  }, [updateImages]);

  useEffect(() => {
    worker.current = new Worker(new URL('../workers/ImageWorker.js', import.meta.url));
    worker.current.onmessage = handleWorkerMessage;

    return () => {
      worker.current?.terminate();
    };
  }, [handleWorkerMessage]);

  useEffect(() => {
    state.requestedImages.forEach((requestedImage: BatchImage) => {
      loadImages(requestedImage);
    });

  }, [loadImages, state.requestedImages])


  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useImageWorker = () => useContext(ImageWorkerContext);
