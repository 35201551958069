import * as React from "react";

const Facebook = ({
  width = 15,
  height = 25,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3989 14.0625L14.0933 9.53809H9.75195V6.60205C9.75195 5.36426 10.3584 4.15771 12.3027 4.15771H14.2764V0.305664C14.2764 0.305664 12.4854 0 10.7729 0C7.19775 0 4.86084 2.16699 4.86084 6.08984V9.53809H0.886719V14.0625H4.86084V25H9.75195V14.0625H13.3989Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
