import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "../../libs/gsap-shockingly-green/src/InertiaPlugin";

// TODO: make general import for Arrow Button
import ArrowButton from "../Carousel/ArrowButton";
import Typography from "../Typography";
import { SliderProps } from "./Slider.types";
import SlideItem from "./SlideItem";
import { hexToRgb } from "../../utils/HexToRgb";
import { padZero } from "../../utils/PadZero";
import useWindowSize from "../../hooks/useWindowSize";
import { useApp } from "../../context/AppContext";

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const SliderEl = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }

  &:after {
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    height: 33.33%;
  }
`;

const TopBackgroundColor = styled.span`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 66.67%;
  background: linear-gradient(180deg, #434343 -1.4%, #aeaeae 111.78%);
  mix-blend-mode: multiply;
`;

const SliderContent = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
`;

const SliderList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
`;

const Overlay = styled.div`
  color: white;
`;

const StyledIndicatorTypography = styled(Typography)`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
`;

const StyledArrowButton = styled(ArrowButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 3.2rem;
  width: 3.2rem;
  bottom: 0;
  margin: auto;
`;

const StyledArrowButtonLeft = styled(StyledArrowButton)`
  left: 1.28rem;
`;

const StyledArrowButtonRight = styled(StyledArrowButton)`
  right: 1.28rem;
`;

export default function Slider({
  inverse,
  products,
  colorTop,
  colorTopAlpha = 1,
  colorBottom,
  colorBottomAlpha = 1,
}: SliderProps) {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  // CONSTS
  const renderProducts =
    products.length === 2 ? [...products, ...products] : products;
  const isSlider = renderProducts.length > 1;
  const ctRGBA = hexToRgb(colorTop || "#434343");
  const cbRGBA = hexToRgb(colorBottom || "#AEAEAE");

  const { width, height } = useWindowSize();

  // STATE
  const [activeIndex, setActiveIndex] = useState(0);
  const [repeatCount] = useState(renderProducts.length);

  // REFS
  const sliderListRef = useRef<HTMLUListElement>(null);
  const slidesRef = useRef<HTMLLIElement[]>([]);

  // OTHER REFS
  const sliderPos = useRef({ x: 0, y: 0 });
  const slideSize = useRef(0);
  const draggableRef = useRef<Draggable>();
  const sliderSize = useRef(0);

  function handlePreviousClicked() {
    goToNextIndex(activeIndex - 1);
  }

  function handleNextClicked() {
    goToNextIndex(activeIndex + 1);
  }

  function goToNextIndex(index: number) {
    let normalizedIndex = index;
    if (index > repeatCount) {
      normalizedIndex = 1;
      sliderPos.current.x = 0;
    } else if (index < 0) {
      normalizedIndex = repeatCount - 1;
      sliderPos.current.x = -sliderSize.current;
    }

    animateToIndex(normalizedIndex);
  }

  const uppdateSliderPosition = (x: number) => {
    if (!sliderListRef.current) return;

    if (x > 0) {
      sliderPos.current.x = -sliderSize.current + x;
    } else if (x < -sliderSize.current) {
      const diff = x - -sliderSize.current;
      sliderPos.current.x = diff;
    } else {
      sliderPos.current.x = x;
    }

    gsap.set(sliderListRef.current, { x: sliderPos.current.x });
  };

  const animateToIndex = useCallback((nextIndex: number) => {
    setActiveIndex(nextIndex);
    const animObj = { x: sliderPos.current.x };
    const targetX = -nextIndex * slideSize.current;
    gsap.to(animObj, {
      x: targetX,
      onUpdate: () => {
        uppdateSliderPosition(animObj.x);
      },
    });
  }, []);

  useEffect(() => {
    if (!sliderListRef.current) return;
    const { width } = sliderListRef.current.getBoundingClientRect();
    const { width: SlideWidth__ } =
      slidesRef.current[0]?.getBoundingClientRect();

    const slideWidth = isMobile ? SlideWidth__ : width;

    slideSize.current = slideWidth;
    sliderSize.current = slideWidth * repeatCount;
  }, [repeatCount, width, height, isMobile]);

  useEffect(() => {
    function handleDrag() {
      if (!draggableRef.current) return;
      const { deltaX } = draggableRef.current;

      const newX = sliderPos.current.x + deltaX;

      uppdateSliderPosition(newX);
    }

    var proxy = document.createElement("div");

    // @ts-ignore: next-line;
    draggableRef.current = Draggable.create(proxy, {
      type: "x",
      inertia: true,
      trigger: sliderListRef.current,
      onDrag: handleDrag,
      onThrowUpdate: handleDrag,
      snap: {
        x: function (endValue) {
          return Math.round(endValue / slideSize.current) * slideSize.current;
        },
      },
    })[0];

    const d = draggableRef.current;

    return () => {
      if (d) d?.kill();
    };
  }, [animateToIndex]);

  return (
    <SliderEl>
      <TopBackgroundColor
        style={{
          background: `linear-gradient(180deg, rgba(${ctRGBA?.r}, ${ctRGBA?.g}, ${ctRGBA?.b}, ${colorTopAlpha}) 0%, rgba(${cbRGBA?.r}, ${cbRGBA?.g}, ${cbRGBA?.b}, ${colorBottomAlpha}) 100%)`,
        }}
      />
      <SliderContent>
        <SliderList ref={sliderListRef}>
          {renderProducts.map((product, i) => (
            <SlideItem
              inverse={inverse}
              {...product}
              key={i}
              ref={(ref: HTMLLIElement) => {
                slidesRef.current[i] = ref;
              }}
            />
          ))}
          {/* NOTE:: ALWAYS ADD ADDITIONAL ITEM FOR INFINITE */}
          {isSlider && <SlideItem inverse={inverse} {...products[0]} />}
        </SliderList>
        {isSlider && !isMobile && (
          <Overlay>
            <StyledIndicatorTypography variant="h8">
              0{(activeIndex % products.length) + 1} /{" "}
              {padZero(products.length, 2)}
            </StyledIndicatorTypography>
            <StyledArrowButtonLeft onClick={handlePreviousClicked} />
            <StyledArrowButtonRight type="right" onClick={handleNextClicked} />
          </Overlay>
        )}
      </SliderContent>
    </SliderEl>
  );
}
