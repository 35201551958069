import React from "react";

import styled from "styled-components";
import { useApp } from "../../../context/AppContext";
import Image from "../../Image";
import Typography from "../../Typography";
import { CollaborationDropProps } from "./CollaborationDrop.types";

const CollaborationDropWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div.attrs(
  (props: { showSingleImageOnMobile: boolean }) => props
)`
  flex: 0 0 50%;

  @media screen and (max-width: 1023px) {
    height: ${(props) => (props.showSingleImageOnMobile ? "100%" : "50%")};
    flex: ${(props) =>
      props.showSingleImageOnMobile ? "0 0 100%" : "0 0 50%"};
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.32rem;

  @media screen and (min-width: 1024px) {
    margin-top: initial;
    top: 28.8rem;
    transform: translateX(-50%);
  }
`;

const BuffaloWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  text-transform: uppercase;
`;

const CollaboratorWrapper = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: -2.5rem;
  }
`;

const XWrapper = styled.div`
  margin-right: -0.35rem;

  @media screen and (min-width: 1024px) {
    margin-right: -1rem;
  }
`;

const Overlay = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--color-black);
  opacity: 0.4;
`;

const CollaborationDrop = ({
  images = [],
  collaboratorName,
  useOverlay = false,
  showSingleImageOnMobile = true,
}: CollaborationDropProps) => {
  const { state } = useApp();
  const { isMobile } = state;

  const renderImages =
    showSingleImageOnMobile && isMobile ? images.slice(0, 1) : images;

  return (
    <CollaborationDropWrapper>
      {renderImages.map((image, index) => (
        <ImageWrapper
          key={`drop-image-${index}`}
          showSingleImageOnMobile={showSingleImageOnMobile}
        >
          {image && (
            <Image src={image.url} alt={image.alt || ""} objectFit="cover" />
          )}
        </ImageWrapper>
      ))}

      {useOverlay && <Overlay />}

      <TextWrapper>
        <BuffaloWrapper>
          <Typography variant={isMobile ? "h5" : "h0"} color="white">
            {"Buffalo Source"}
          </Typography>

          <XWrapper>
            <Typography variant={isMobile ? "h5" : "h0"} color="white">
              {"x"}
            </Typography>
          </XWrapper>
        </BuffaloWrapper>

        <CollaboratorWrapper>
          <Typography variant={isMobile ? "h5" : "h0"} color="white">
            {collaboratorName}
          </Typography>
        </CollaboratorWrapper>
      </TextWrapper>
    </CollaborationDropWrapper>
  );
};

export default CollaborationDrop;
