import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { variant } from "styled-system";

import TypographyProps from "./Typography.types";
import { motion } from "framer-motion";

const TypographyRoot = styled(motion.div)<TypographyProps>(
  {
    all: "unset",
    display: "block",
    padding: "0",
    margin: "0",

    "&.white": {
      color: "var(--color-white)",
    },

    "&.black": {
      color: "var(--color-black)",
    },
  },
  variant({
    variants: {
      h0: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "9.6rem",
        lineHeight: "10.5rem",
        letterSpacing: "0.45rem",
      },

      h1: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "8rem",
      },

      h2: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "5.2rem",
        lineHeight: "0.8em",
      },

      h3: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",

        fontSize: "4.2rem",
      },

      h4: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "2.9rem",
      },

      h5: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "2.4rem",
        lineHeight: "2rem",
        letterSpacing: "0.185rem",
      },

      h6: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "1.8rem",
        letterSpacing: "0.1rem",
      },

      h7: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "1.6rem",
        letterSpacing: "0.1rem",
      },

      h8: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "1.4rem",
        letterSpacing: "0.1rem",
      },

      h9: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "1.2rem",
        lineHeight: '1.4em',
        letterSpacing: "0.1rem",
      },

      h10: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "1rem",
        letterSpacing: "0.1rem",
      },

      h11: {
        fontFamily: "var(--font-family-suissnord)",
        fontWeight: "var(--heading-regular-font)",
        fontSize: "0.6rem",
        letterSpacing: "0.1rem",
      },

      body1: {
        fontFamily: "var(--font-family-lato-regular)",
        fontWeight: "var(--body-bold-font)",
        fontSize: "2rem",
      },

      body2: {
        fontFamily: "var(--font-family-lato-regular)",
        fontWeight: "var(--body-regular-font)",
        fontSize: "1.6rem",
      },

      body3: {
        fontFamily: "var(--font-family-lato-regular)",
        fontWeight: "var(--body-bold-font)",
        fontSize: "1.4rem",
        lineHeight: "2rem",
      },

      body4: {
        fontFamily: "var(--font-family-lato-regular)",
        fontWeight: "var(--body-regular-font)",
        fontSize: "1.4rem",
        lineHeight: "1.8rem",
      },

      body5: {
        fontFamily: "var(--font-family-lato-regular)",
        fontWeight: "var(--body-regular-font)",
        fontSize: "1rem",
        lineHeight: "1.2rem",
      },

      body6: {
        fontFamily: "var(--font-family-lato-thin)",
        fontWeight: "var(--body-thin-font)",
        fontSize: "1rem",
        lineHeight: "1.2rem",
        '-webkit-font-smoothing': 'initial'
      },
    },
  })
);

const defaultVariantMapping = {
  h0: "h1",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  h7: "h6",
  h8: "h6",
  h9: "h6",
  h10: "h6",
  h11: "h6",
  body1: "p",
  body2: "p",
  body3: "p",
  body4: "p",
  body5: "p",
};

// eslint-disable-next-line react/display-name
const Typography = React.forwardRef(
  (
    {
      variant = "body1",
      color = "currentColor",
      alignment = "left",
      children,
      className,
      component,
      useShadow = false,
      html,
    }: TypographyProps,
    ref
  ) => {
    const [componentType, setComponentType] = useState<React.ElementType>();

    useEffect(() => {
      if (component !== null) {
        setComponentType(component);
      } else {
        setComponentType(defaultVariantMapping[variant as keyof React.ElementType]);
      }
    }, [component, variant]);

    const options: { children?: ReactNode, dangerouslySetInnerHTML?: { __html: string } } = {};
    if (html) {
      options.dangerouslySetInnerHTML = { __html: html };
    } else {
      options.children = children;
    }

    return (
      <TypographyRoot
        variant={variant}
        className={clsx(className, color)}
        as={componentType}
        ref={ref}
        style={{
          textAlign: alignment,
          textShadow: useShadow ? "0px 3px 4px rgba(0, 0, 0, 0.15)" : "0",
        }}
        {...options}
      />

    );
  }
);
export default Typography;
