import React, { forwardRef } from "react";
import styled from "styled-components";
import { useApp } from "../../context/AppContext";
import Image from "../Image";
import Typography from "../Typography";
import { SliderProduct } from "./Slider.types";

interface SlideItemElProps {
  inverse?: boolean;
}

const SlideItemEl = styled.li<SlideItemElProps>`
  position: relative;
  flex: 0 0 88%;
  height: 100%;
  border-left: ${(props) => (!props.inverse ? "1px solid black;" : "none")};
  border-right: ${(props) => (props.inverse ? "1px solid black;" : "none")};

  @media screen and (min-width: 1024px) {
    flex: 0 0 100%;
  }
`;

const SliderItemContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 70%;
  height: 70%;

  user-select: none;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;
const StyledProductImage = styled(Image)`
  position: absolute;

  > img {
    object-position: 50% 90%;
  }
`;

const HeaderLabel = styled(Typography)`
  position: absolute;
  top: 2.4rem;
  left: 2.4rem;
  color: white;
`;

const LabelBracket = styled.span`
  &:first-child {
    margin: 0 1.5rem 0 0;
  }

  &:last-child {
    margin: 0 0 0 1.5rem;
  }
`;

const StyledTypography = styled(Typography)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2.4rem 2.6rem;
  text-transform: uppercase;
`;

const Price = styled.span`
  white-space: nowrap;
`;

const SlideItem = forwardRef<HTMLLIElement, SliderProduct>(
  ({ inverse, name, price, image, soldOut = false }: SliderProduct, ref) => {
    // const SlideItem = ({ name, price, image, soldOut = false }: SliderProduct) => {
    const { state } = useApp();
    const { isMobile, isDebug } = state;

    const debugStyle = {
      border: "1px solid red",
    };

    return (
      <SlideItemEl inverse={inverse} ref={ref}>
        <SliderItemContent>
          <ImageWrapper style={isDebug ? debugStyle : {}}>
            {image && (
              <StyledProductImage
                src={image.url}
                alt={image.alt || ""}
                objectFit="contain"
              />
            )}
          </ImageWrapper>
          {soldOut && (
            <HeaderLabel variant={isMobile ? "h9" : "h8"}>
              <LabelBracket>[</LabelBracket>SOLD OUT
              <LabelBracket>]</LabelBracket>
            </HeaderLabel>
          )}
          <StyledTypography variant="body3">
            <span>{name}</span>
            <Price>{price} EUR</Price>
          </StyledTypography>
        </SliderItemContent>
      </SlideItemEl>
    );
  }
);

export default SlideItem;

SlideItem.displayName = "SlideItem";
