import React from 'react'
import styled from 'styled-components'
import AnimatedLink from '../AnimatedLink'

import Image from '../Image'
import { Theme } from '../ThemeWrapper/ThemeWrapper.types'
import Typography from '../Typography'

import { PageFooterProps } from './PageFooter.types'


const PageFooterEl = styled.div`
  position: relative;
  background-color: rgba(18, 18, 18, 0.8); // #121212;
  color: white;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-image: url('/images/grain.png');
    filter: invert(0.3);
  }
`

const Top = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 18.6rem;

  @media screen and ( min-width: 1024px) {
    height: 35rem;
  }
`

const Bottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  font-family: 'Lato Thin';
  
  height: 8rem;
  padding: 1.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  
  @media screen and ( min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;
  }
`

const SocialList = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 1.2rem;
  width: 100%;
  
  @media screen and ( min-width: 1024px) {
    justify-content: center;
    padding: 0;
  }
`

const SocialListItem = styled.li`
  margin: 4.5rem 0 2rem 0;

  @media screen and ( min-width: 1024px) {
    margin: 8rem 2.4rem 4rem 2.4rem;
  }
`

const StyledFooterCopy = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;

  display: flex;
  align-items: center;
`

const StyledFooterCopyLink = styled(StyledFooterCopy)`
  margin-left: 2.4rem;
  @media screen and ( min-width: 1024px) {
    margin-left: 0;
  }
`

const ImageWrapper = styled.div`
  width: 16.8rem;

  @media screen and ( min-width: 1024px) {
    width: 27rem;
  }
`

const ArrowSpan = styled.span`
  position: absolute;
  left: -2.4rem;
  display: inline-block;
  
  font-size: 1.5em;
  
  @media screen and ( min-width: 1024px) {
    font-size: 1.7em;
  }
`

export default function PageFooter({ socials, copyright, link }: PageFooterProps) {

  return (
    <PageFooterEl theme={Theme.DARK}>
      <Top>
        <ImageWrapper>
          <Image src="/images/buffalo-logo.png" alt="Buffalo Source Logo" />
        </ImageWrapper>
        <SocialList>
          {socials?.map(social => (
            <SocialListItem key={social.label}>
              <AnimatedLink url={social.url.url} target="_blank">
                <Typography variant='h9' color='white'>
                  {social.label}
                </Typography>
              </AnimatedLink>
            </SocialListItem>
          ))}
        </SocialList>
      </Top>
      <Bottom>
        {copyright &&
          <StyledFooterCopy>
            <Typography variant='body6'>
              {copyright}
            </Typography>
          </StyledFooterCopy>
        }
        {link &&
          <a href={link.url} target={link.target}>
            <StyledFooterCopyLink>
              <Typography variant='body6'>
                <ArrowSpan>{"<"}</ArrowSpan>
                {link.label}
              </Typography>
            </StyledFooterCopyLink>
          </a>
        }
      </Bottom>
    </PageFooterEl>
  )
}
