import React from "react";

import styled from "styled-components";
import { useApp } from "../../../context/AppContext";
import Image from "../../Image";
import ParallaxWrapper from "../../ParallaxWrapper";
import ThemeWrapper from "../../ThemeWrapper";
import Typography from "../../Typography";
import Spacer from "../Spacer";
import { SplitTextAProps } from "./SplitTextA.types";

const SplitTextAWrapper = styled(ThemeWrapper)`
  padding-left: 0;
  padding-top: 6.4rem;
  padding-right: 0;
  padding-bottom: 8rem;

  @media screen and (min-width: 1024px) {
    padding: 12rem 4.2rem 13.2rem 19.2rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 58.5rem;
    padding-right: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 28.4rem;
  height: 37.8rem;

  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    width: 52rem;
    height: 69.2rem;
    margin: 0;

    padding-left: 0;
    padding-right: 0;
  }
`;

const BodyWrapper = styled.div`
  width: 28.4rem;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    width: 33.6rem;
    margin: 0 0 0 6rem;
  }
`;

const StyledTitleTypography = styled(Typography)`
  @media screen and (max-width: 1023px) {
    padding-right: 3.4rem;
  }
`;

const StyledBodyTypograhy = styled(Typography)`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const SplitTextA = ({
  theme = "light",
  image,
  title,
  body,
}: SplitTextAProps) => {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  return (
    <SplitTextAWrapper theme={theme}>
      <ContentWrapper>
        <ImageWrapper>
          <ParallaxWrapper
            positions={{
              start: 150,
              startMobile: 50,
              end: -50,
              endMobile: -20,
            }}
          >
            {image && (
              <Image src={image.url} alt={image.alt || ""} objectFit="cover" />
            )}
          </ParallaxWrapper>
        </ImageWrapper>
        {isMobile && <Spacer size={8} />}
        <TextWrapper>
          <StyledTitleTypography variant={isMobile ? "h5" : "h2"}>
            {title}
          </StyledTitleTypography>
          <Spacer size={isMobile ? 4 : 8} />
          {body && (
            <BodyWrapper>
              {body?.map((p, i) => (
                <StyledBodyTypograhy
                  key={i}
                  variant={isMobile ? "body4" : "body2"}
                >
                  {p}
                </StyledBodyTypograhy>
              ))}
            </BodyWrapper>
          )}
        </TextWrapper>
      </ContentWrapper>
    </SplitTextAWrapper>
  );
};

export default SplitTextA;
