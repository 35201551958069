import React from "react";

import styled from "styled-components";
import Typography from "../../Typography";
import { ArrowButtonProps } from "./ArrowButton.styles";

const ArrowButtonWrapper = styled.div`
  cursor: pointer;
`;

const Card = ({ className, type = "left", onClick, color = "currentColor" }: ArrowButtonProps) => {
  return (
    <ArrowButtonWrapper onClick={onClick} className={className}>
      <Typography variant="h6" color={color}>
        {type === "left" ? "<" : ">"}
      </Typography>
    </ArrowButtonWrapper>
  );
};

export default Card;
