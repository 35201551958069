import './App.css';
import { DropPage, Home, FourOhFour, Preview } from './pages'

import { Routes, Route, Link, HashRouter } from 'react-router-dom';
import { client, repositoryName } from './prismic';
import { ReactLenis } from '@studio-freight/react-lenis';

import './styles/globals.css';

import { PrismicProvider, PrismicToolbar } from '@prismicio/react';
import { AppProvider } from './context/AppContext';
import { ImageWorkerProvider } from './context/ImageWorkerContext';

import { useExternalScripts } from './hooks/useExternalScripts';


function App() {
  // PREVIEW SCRIPT;
  useExternalScripts("https://static.cdn.prismic.io/prismic.js?new=true&repo=buffalosource");

  return (
    <PrismicProvider
      client={client}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <HashRouter>
        <AppProvider>
          <ImageWorkerProvider>
            <ReactLenis root>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/drop/:uid" element={<DropPage />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="*" element={<FourOhFour />} />
              </Routes>
            </ReactLenis>
          </ImageWorkerProvider>
        </AppProvider>
      </HashRouter>
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
  );
}

export default App;
