import React, { useState } from 'react';
import styled from 'styled-components';
import AnimatedLink from '../AnimatedLink';
import SecondaryDrop from '../layout/SecondaryDrop';
import ThemeWrapper from '../ThemeWrapper';
import { Theme } from '../ThemeWrapper/ThemeWrapper.types';
import Typography from '../Typography';
import { OtherDropsProps } from './OtherDrops.types';

const StyledThemeWrapper = styled(ThemeWrapper)`
  padding-bottom: 10rem;

  @media screen and (min-width: 1024px) {
    padding-bottom: 15rem;
  }
`

const DropList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const DropListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 8rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.7rem 0 0;
  
  @media screen and (min-width: 1024px) {
    padding: 6rem 0 0;
  }
`

export default function OtherDrops({ className, drops }: OtherDropsProps) {
  const [loadedMore, setLoadedMore] = useState(drops.length <= 3);

  function handleLoadMoreClicked() {
    setLoadedMore(true);
  }

  const renderDrops = !loadedMore ? drops.slice(0, 3) : drops;

  return (
    <StyledThemeWrapper className={className} theme={Theme.LIGHT}>
      <DropList>
        {renderDrops.map((drop, i) => (
          <DropListItem key={`${drop.name}-${i}`}>
            <SecondaryDrop {...drop} inverse={i % 2 === 0} theme={Theme.LIGHT} />
          </DropListItem>
        ))}
      </DropList>

      {!loadedMore && <ButtonWrapper>
        <AnimatedLink url="#" onClick={handleLoadMoreClicked} reversed>
          <Typography variant='h8' component="span">
            Load More
          </Typography>
        </AnimatedLink>
      </ButtonWrapper>}
    </StyledThemeWrapper>
  )
}
