import React, { Fragment, useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import Typography from "../Typography";
import { CountDownProps } from "./CountDown.types";
import Digit from "./Digit";

const CountDownWrapper = styled.div`
  display: flex;
  width: max-content;
  position: relative;
`;

const TimeItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  position: relative;
`;

const TimeValueWrapper = styled.div.attrs(
  (props: { width: string; height: string }) => props
)`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  position: relative;
`;

const StyledColonText = styled(Typography).attrs(
  (props: { marginTop: string, marginSide: string }) => props
)`
  margin-left: ${(props) => props.marginSide};
  margin-right: ${(props) => props.marginSide};
  margin-top: ${(props) => props.marginTop};
`;

const CountDown = ({
  type = "medium",
  color = "currentColor",
  launchDate,
}: CountDownProps) => {
  const daysText = "days";
  const hoursText = "hours";
  const minutesText = "minutes";
  const secondsText = "seconds";
  const [timeValueSize, setTimeValueSize] = useState("");
  const [timeTextSize, setTimeTextSize] = useState("");
  const [daysWidth, setDaysWidth] = useState("");
  const [colonProps, setColonProps] = useState({
    size: "",
    marginTop: "",
    marginSide: ""
  });
  const [typographyProps, setTypographyProps] = useState({
    width: "",
    height: "",
  });
  const [animationDistance, setAnimationDistance] = useState(0);

  const [timeArray, setTimeArray] = useState([
    { text: daysText, value: 0 },
    { text: hoursText, value: 0 },
    { text: minutesText, value: 0 },
    { text: secondsText, value: 0 },
  ]);

  const updateCounter = useCallback(() => {
    function updateDaysWidth(days: number) {
      let daysNrLength = days.toString().length;
      daysNrLength = daysNrLength <= 1 ? 2 : daysNrLength;

      switch (type) {
        case "small":
          setDaysWidth(`${daysNrLength * 2.4}rem`);
          break;
        case "medium":
          setDaysWidth(`${daysNrLength * 5.1}rem`);
          break;
        case "large":
          setDaysWidth(`${daysNrLength * 9.9}rem`);
          break;
        default:
          break;
      }
    }

    // create a local date object
    const localDate = new Date();
    // get the UTC time
    const utcTime = localDate.getTime() + (localDate.getTimezoneOffset() * 60000);
    // create a new date object with the UTC time
    const utcDate = new Date(utcTime);

    const remainingTime = launchDate.getTime() - utcDate.getTime();

    // Set everything to zero, because otherwise the time will show minuses.
    if (remainingTime <= 0) {
      setTimeArray([
        { text: daysText, value: 0 },
        { text: hoursText, value: 0 },
        { text: minutesText, value: 0 },
        { text: secondsText, value: 0 },
      ]);
      return;
    }

    let totalDays = Math.floor(remainingTime / (1000 * 3600 * 24));
    let totalHours = Math.floor((remainingTime / (1000 * 3600)) % 24);
    let totalMinutes = Math.floor((remainingTime / (1000 * 60)) % 60);
    let totalSeconds = Math.floor((remainingTime / 1000) % 60);

    updateDaysWidth(totalDays);

    setTimeArray([
      { text: daysText, value: totalDays },
      { text: hoursText, value: totalHours },
      { text: minutesText, value: totalMinutes },
      { text: secondsText, value: totalSeconds },
    ]);
  }, [launchDate, type])


  useEffect(() => {
    updateCounter();
    function handleTick() {
      updateCounter();
    }

    const intervalId = setInterval(handleTick, 1000);

    switch (type) {
      case "small":
        setTimeValueSize("h5");
        setTimeTextSize("h11");
        setColonProps({ size: "h6", marginTop: "0rem", marginSide: "0.72rem" });
        setTypographyProps({
          width: "5rem",
          height: "2.6em",
        });
        setDaysWidth("4.8rem");
        setAnimationDistance(20);
        break;
      case "medium":
        setTimeValueSize("h2");
        setTimeTextSize("h10");
        setColonProps({ size: "h3", marginTop: "-0.2rem", marginSide: "1.1rem" });
        setTypographyProps({
          width: "10.2rem",
          height: "5.2rem",
        });
        setDaysWidth("10.2rem");
        setAnimationDistance(50);
        break;

      case "large":
        setTimeValueSize("h0");
        setTimeTextSize("h8");
        setColonProps({ size: "h1", marginTop: "0.8rem", marginSide: '1.1rem' });
        setTypographyProps({
          width: "18.8rem",
          height: "9.8em",
        });
        setDaysWidth("18.8rem");
        setAnimationDistance(80);
        break;
      default:
        break;
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [type, updateCounter]);

  function stringToInt(num: string) {
    return Number(num);
  }

  return (
    <CountDownWrapper>
      {timeArray.map((time, index) => (
        <Fragment key={index}>
          <TimeItemWrapper key={index}>
            <TimeValueWrapper
              width={index === 0 ? daysWidth : typographyProps.width}
              height={typographyProps.height}
            >
              {time.value.toString().length === 1 && (
                <Digit
                  value="0"
                  headingType={timeValueSize}
                  color={color}
                  distance={animationDistance}
                />
              )}

              {Array.from(String(time.value), stringToInt).map(
                // Days can be more than 2 digits. So map all the numbers.
                (number, index) => (
                  <Digit
                    key={index}
                    value={number.toString()}
                    headingType={timeValueSize}
                    color={color}
                    distance={animationDistance}
                  />
                )
              )}
            </TimeValueWrapper>
            <Typography variant={timeTextSize} color={color}>
              {time.text}
            </Typography>
          </TimeItemWrapper>
          {index !== timeArray.length - 1 && (
            <StyledColonText
              variant={colonProps.size}
              color={color}
              marginTop={colonProps.marginTop}
              marginSide={colonProps.marginSide}
            >
              :
            </StyledColonText>
          )}
        </Fragment>
      ))}
    </CountDownWrapper>
  );
};

export default CountDown;
