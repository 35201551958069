import { asText } from '@prismicio/helpers';
import { useSinglePrismicDocument } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import React, { useEffect } from 'react'
import ErrorPage from '../components/ErrorPage'

export default function FourOhFour() {
  const [notFoundPage, notFoundPageState] = useSinglePrismicDocument<PrismicDocument>("notfoundpage");

  useEffect(() => {
    if (notFoundPageState.state === "failed") {
      console.error('NotFoundPage not Found');
    }
  }, [notFoundPageState.state]);

  return (
    <ErrorPage
      title={asText(notFoundPage?.data.title) || 'Error'}
      body={notFoundPage?.data.content.map((p: any) => p.text) || ['This page can\'t be found']}
      link={{
        label: notFoundPage?.data.link_label || "buffalo-boots.com",
        url: notFoundPage?.data.link_url.url
      }}
    ></ErrorPage>
  )
}
