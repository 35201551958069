import * as React from "react";

const NewTab = ({
  width = 18,
  height = 18,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.391 5.24576C16.0966 4.7551 14.3872 2.83009 14.1706 0.493679V0H10.3142V15.305C10.3078 17.0954 8.85128 18.5418 7.06094 18.5355C6.05209 18.5318 5.10268 18.0589 4.49213 17.2563C3.40939 15.831 3.68713 13.7978 5.11208 12.7151C5.95201 12.077 7.04985 11.8876 8.05535 12.2073V8.2891C4.17913 7.75378 0.602478 10.4616 0.0668199 14.3375C-0.233081 16.5067 0.486951 18.6923 2.01702 20.2586C4.7524 23.0575 9.23883 23.1089 12.0377 20.3735C13.4019 19.0402 14.1709 17.2136 14.1709 15.3061V7.48545C15.7295 8.59975 17.5981 9.1972 19.5141 9.19384V5.36196C19.1369 5.36229 18.7605 5.32333 18.3914 5.24542L18.391 5.24576Z"
        fill={color}
      />
    </svg>
  );
};

export default NewTab;
