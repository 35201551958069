import { AspectRatios } from "./Carousel.types";

export function getWidthForAspectRatio(ratio: string, isMobile: boolean): number {
  if (isMobile) return 100;
  switch (ratio) {
    case AspectRatios.LANDSCAPE_16_9:
      return 100;
    case AspectRatios.PORTRAIT_9_16:
      return 36;
    case AspectRatios.SQUARE:
      return 40;
    case AspectRatios.LANDSCAPE_4_3:
      return 60;
    case AspectRatios.PORTRAIT_3_4:
      return 40;
    default:
      return 50;
  }
}

export function getAspectRatioInPercentage(ratio: string, isMobile: boolean) {
  if (isMobile) return 100;
  switch (ratio) {
    case AspectRatios.LANDSCAPE_16_9:
      return 9 / 16 * 100;
    case AspectRatios.PORTRAIT_9_16:
      return 16 / 9 * 100;
    case AspectRatios.SQUARE:
      return 100;
    case AspectRatios.LANDSCAPE_4_3:
      return 3 / 4 * 100;
    case AspectRatios.PORTRAIT_3_4:
      return 4 / 3 * 100;
    default:
      return 100;
  }
}