import { useEffect } from "react";
import { useSinglePrismicDocument } from "@prismicio/react";
import FourOhFour from "./FourOhFour";
import { ComponentMapper } from "../prismic/ComponentMapper";
import PageFooter from "../components/PageFooter";
import { PrismicDocument } from "@prismicio/types";
import Header from "../components/Header";
import PageBackground from "../components/PageBackground";


export default function Home() {
  const [header, headerState] = useSinglePrismicDocument<PrismicDocument>("header");
  const [home, homeState] = useSinglePrismicDocument<PrismicDocument>("homepage", {
    fetchLinks: [
      'droppage.title',
      'droppage.sd_title',
      'droppage.sd_link_label',
      'droppage.sd_image',
      'droppage.sd_products',
      'product.price',
      'product.name',
      'product.color',
      'product.sold_out',
      'product.link_url',
      'product.secondary_drop_image',
      'droppage.sd_color_top',
      'droppage.sd_color_top_alpha',
      'droppage.sd_color_bottom',
      'droppage.sd_color_bottom_alpha',
    ]
  });

  const [footer, footerState] = useSinglePrismicDocument<PrismicDocument>("footer");
  const notFound = homeState.state === "failed" || footerState.state === "failed" || headerState.state === "failed";

  useEffect(() => {
    if (notFound) {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [notFound]);

  if (header && home && footer) {
    return (
      <PageBackground>
        <Header
          logoUrl="/"
          link={{
            label: header.data.link_label,
            labelMobile: header.data.link_label_mobile,
            url: header.data.link_url
          }}
          ribbon={header.data.sales_ribbon_active ? {
            text: header.data.sales_ribbon_text,
            link: {
              label: header.data.sales_ribbon_link_label,
              url: header.data.sales_ribbon_link_url
            }
          } : undefined}
        />
        <ComponentMapper components={home.data.body} />
        {/* <ComponentMapper components={SecondaryDrops} /> */}
        <PageFooter
          img={{
            url: ''
          }}
          socials={footer.data.socials}
          copyright={footer.data.copyright}
          link={{
            url: footer.data.link_url.url,
            label: footer.data.link_label,
            target: footer.data.link_url.target
          }}
        />
      </PageBackground>
    );
  } else if (notFound) {
    return <FourOhFour />;
  }

  return null;
};