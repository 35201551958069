import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import Typography from "../../Typography";
import { DividerProps } from "./Divider.types";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { useApp } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8rem;
  overflow: hidden;
  white-space: nowrap;
  border-top: solid var(--color-black);
  border-bottom: solid var(--color-black);
  border-width: 1px;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 11rem;
  }

  background: url(/images/grain.png) repeat, var(--color-grey-light);
  background-blend-mode: multiply, normal;
`;

const TypographyWrapper = styled(motion.div) <any>`
  display: flex;
  width: max-content;
  position: relative;
  text-transform: uppercase;
`;

const LabelWrapper = styled.div<any>``;

const Divider = ({ labelText }: DividerProps) => {
  const { state } = useApp()
  const { isMobile } = state;

  const { width, height } = useWindowSize();

  const { scrollYProgress } = useScroll();
  const labelRef = useRef<HTMLElement>();
  const [labelWidth, setLabelWidth] = useState(0);
  const labelProgressX = useTransform(
    scrollYProgress,
    [0, 1],
    [-labelWidth / 2, 0]
  );

  const labelProgressSpringX = useSpring(labelProgressX, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    if (!labelRef.current) return;
    setLabelWidth(labelRef.current.clientWidth);
  }, [width, height]);

  return (
    <DividerWrapper>
      <TypographyWrapper
        style={{ x: labelProgressSpringX }}
        transition={{
          stiffness: 100,
          damping: 30,
          restDelta: 0.001,
        }}
      >
        <LabelWrapper ref={labelRef}>
          <Typography variant={isMobile ? "h5" : "h0"} color="black">
            &nbsp;&nbsp;{labelText}&nbsp;&nbsp;{labelText}
          </Typography>
        </LabelWrapper>
      </TypographyWrapper>
    </DividerWrapper>
  );
};

export default Divider;
