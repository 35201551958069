import React, { forwardRef } from "react";
import styled from "styled-components";
import { useApp } from "../../context/AppContext";
import Typography from "../Typography";
import { SalesRibbonProps } from "./SalesRibbon.types";

const SalesRibbonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  font-family: "Suissnord Regular";

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    height: 4.8rem;
  }

  background: url(/images/grain.png) repeat, var(--color-white);
  background-blend-mode: multiply, normal;
  filter: invert(1);
`;

const StyledLink = styled.a`
  > * {
    text-decoration: underline;
  }
`;

const SalesRibbon = forwardRef<HTMLDivElement, SalesRibbonProps>(({ className, text, link }: SalesRibbonProps, ref) => {
  const { state } = useApp();
  const { isMobile } = state;

  return (
    <SalesRibbonWrapper ref={ref} className={className} >
      <Typography
        variant={isMobile ? "h9" : "h8"}
        color="black"
        alignment="center"
      >
        {text} {!isMobile && link?.url && link?.label ? "-" : ""}
      </Typography>

      {link?.label && link?.url &&
        <StyledLink href={link.url} style={{ marginLeft: isMobile ? "" : "0.35rem" }} target="_blank">
          <Typography
            component="span"
            variant={isMobile ? "h9" : "h8"}
            color="black"
            alignment="center"
          >
            {link.label}
          </Typography>
        </StyledLink>
      }
    </SalesRibbonWrapper>
  );
});

export default SalesRibbon;
