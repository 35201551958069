import React from "react";
import Marquee from "react-fast-marquee";

import styled from "styled-components";
import { useApp } from "../../context/AppContext";

import TickerButton from "../TickerButton";
import Typography from "../Typography";
import { ProjectTapeProps } from "./ProjectTape.types";

// NOTE: must be above ProjectTapeWrapper
const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: 'cover';
`;

const ProjectTapeWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  cursor: pointer;
  background-color: transparent;

  .has-hover & {
    &:hover {
      background-color: var(--color-black);
      color: white;
  
      ${Image}{
        filter: invert(1);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    white-space: nowrap;
  }
`;

const StyledMarquee = styled(Marquee)`
  height: 11.5rem;
  overflow-y: hidden;
  border-top: 0.1rem solid;
  border-bottom: 0.1rem solid;
  border-color: var(--color-black);
`;

const TextWrapper = styled.div`
  /* height: 6.1rem; */

  @media screen and (min-width: 1024px) {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const DesktopWrapper = styled.div`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.1rem;
  border-top: 0.1rem solid;
  border-color: var(--color-black);
  border-bottom: 0.1rem solid;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6.1rem;
  border-bottom: 0.1rem solid;
`;

const ImageWrapper = styled.div`
  height: 20.5rem;
  width: 100%;
  margin-right: 5.6rem;
  border-bottom: 0.1rem solid var(--color-black);

  @media screen and (min-width: 1024px) {
    width: 20.3rem;
    height: 100%;
    border-bottom: none;
  }
`;

const ProjectTape = ({
  className,
  text,
  image,
  url,
  direction = "left",
}: ProjectTapeProps) => {
  const { state } = useApp()
  const { isMobile } = state;

  return (
    <ProjectTapeWrapper className={className} href={url}>
      {isMobile ? (
        <MobileWrapper>
          <HeaderWrapper>
            <Typography variant="h5">
              {"BUFFALO SOURCE"} {"X"} {text}
            </Typography>
          </HeaderWrapper>

          <ImageWrapper>
            {image && <Image src={image.url} alt={image.alt || ''} />}
          </ImageWrapper>

          <ButtonWrapper>
            <TickerButton
              as="button"
              width="22.4rem"
              height="3.1rem"
              text="View Project"
              useMarquee={false}
            />
          </ButtonWrapper>
        </MobileWrapper>
      ) : (
        <DesktopWrapper>
          <StyledMarquee
            play={true}
            gradient={false}
            speed={75}
            direction={direction}
          >
            <TextWrapper>
              <Typography variant="h2">
                {"BUFFALO SOURCE"}
              </Typography>
            </TextWrapper>

            <Typography variant="h2">
              {"X"}
            </Typography>

            <TextWrapper>
              <Typography variant="h2">
                {text}
              </Typography>
            </TextWrapper>

            <ImageWrapper>
              <Image src={image.url} alt={image.alt || ''} />
            </ImageWrapper>

            <TickerButton
              as="button"
              text="View Project"
              useMarquee={false}
            />
          </StyledMarquee>
        </DesktopWrapper>
      )}
    </ProjectTapeWrapper>
  );
};

export default ProjectTape;
