import React from 'react'
import styled from 'styled-components';
import CountDown from '../../CountDown'
import SecondaryHero from '../../SecondaryHero'
import Image from '../../Image'
import Typography from '../../Typography';
import { MainDropProps } from './MainDrop.types'
import TickerButton from '../../TickerButton';
import ThemeWrapper from '../../ThemeWrapper';
import ParallaxWrapper from '../../ParallaxWrapper';
import { useApp } from '../../../context/AppContext';
import { Link } from 'react-router-dom';

const MainDropEl = styled(ThemeWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 4rem;

  @media screen and (min-width: 1024px) {
    padding-bottom: 8rem;
  }
`

const StyledSecondaryHero = styled(SecondaryHero)`
  margin-bottom: 4rem;

  @media screen and (min-width: 1024px) {
    margin-bottom: 6rem;
  }
`

const CounterWrapper = styled(ParallaxWrapper)`
  display: flex;
  align-items: center;
  margin:  0 0 4rem 0;

  @media screen and (min-width: 1024px) {
    margin:  0 0 8rem 0;
  }
`

const ColumnWrapper = styled(ParallaxWrapper)`
  padding: 0 3.8rem;
  
  @media screen and (min-width: 1024px) {
    display: flex;
    max-width: 106rem;
    margin: auto;
  }
`

const Column = styled.div`

  &:first-child {
    margin-bottom: 4rem;
  }
  
  &:last-child {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
  
    &:first-child {
      padding-right: 6%;
    }
    
    &:last-child {
      display: block;
      padding-left: 6%;
    }
  }
`

const StyledBody = styled(Typography)`
  margin-bottom: 1em;
`

const StyledTickerButton = styled(TickerButton)`
  margin: 3rem auto 8rem;

  @media screen and (min-width: 1024px) {
    margin: 3rem 0 7.2rem;
  }
`

const StyledContentImageRight = styled(Image)`
  @media screen and (min-width: 1024px) {
    margin-top: 22.6rem;
  }  
`

export default function MainDrop({ hero, countdown, content }: MainDropProps) {
  const { state } = useApp();
  const { isMobile } = state;


  return (
    <MainDropEl>
      {hero && <StyledSecondaryHero
        images={hero.images}
        title={hero.collaboratorName}
        launchDate={new Date()}
        url={content.link.url}
        showRibbon={hero.showRibbon}
        showSingleImageOnMobile={false}
      />}
      {countdown?.visible &&
        <CounterWrapper
          positions={{
            start: 30,
            startMobile: 50,
            end: -10,
            endMobile: 0,
          }}
          offset={['start end', 'start 40%']}
        >
          {countdown?.date && <CountDown type={isMobile ? 'small' : 'large'} color='black' launchDate={countdown.date} />}
        </CounterWrapper>
      }
      <ColumnWrapper
        positions={{
          start: 100,
          startMobile: 50,
          end: 0,
          endMobile: 0
        }}
        offset={['start end', 'start 50%']}
      >
        <Column>
          {content?.images?.[0] &&
            <Link to={content.link.url}>
              <Image width='auto' height='auto' src={content.images[0].url} alt={content.images[0].alt} objectFit="cover" />
            </Link>
          }
        </Column>
        <Column>
          {content.body?.map((p, i) => (
            <StyledBody key={i} color='black' variant='body3'>
              {p}
            </StyledBody>
          ))}
          {content?.link?.label && <StyledTickerButton text={content.link.label} href={content.link.url} variant="small" />}
          {content?.images?.[1] &&
            <ParallaxWrapper
              positions={{
                start: 60,
                startMobile: 50,
                end: -20,
                endMobile: 0,
              }}
              offset={['start end', 'start 50%']}
            >
              <Link to={content.link.url}>
                <StyledContentImageRight width='auto' height='auto' src={content.images[1].url} alt={content.images[1].alt} objectFit="cover" />
              </Link>
            </ParallaxWrapper>
          }
        </Column>
      </ColumnWrapper>
    </MainDropEl>
  )
}
