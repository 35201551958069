import * as React from "react";

const Linkedin = ({
  width = 23,
  height = 24,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87695 21.9995H1.3418V7.39502H5.87695V21.9995ZM3.60693 5.40283C2.15674 5.40283 0.980469 4.20166 0.980469 2.75146C0.980469 2.05488 1.25718 1.38683 1.74974 0.894274C2.2423 0.401716 2.91035 0.125 3.60693 0.125C4.30352 0.125 4.97157 0.401716 5.46412 0.894274C5.95668 1.38683 6.2334 2.05488 6.2334 2.75146C6.2334 4.20166 5.05664 5.40283 3.60693 5.40283ZM22.8506 21.9995H18.3252V14.8901C18.3252 13.1958 18.291 11.0229 15.9673 11.0229C13.6094 11.0229 13.248 12.8638 13.248 14.7681V21.9995H8.71777V7.39502H13.0674V9.38721H13.1309C13.7363 8.23975 15.2153 7.02881 17.4219 7.02881C22.0117 7.02881 22.8555 10.0513 22.8555 13.9771V21.9995H22.8506Z"
        fill={color}
      />
    </svg>
  );
};

export default Linkedin;
