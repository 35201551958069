import { motion, MotionValue, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useApp } from '../../context/AppContext'
import { ParallaxWrapperProps } from './ParallaxWrapper.types'

const ParallaxWrapperEl = styled(motion.div)`
  
`

// HELPER FUNCTION
function useParallax(value: MotionValue<number>, start: number, end: number = 0) {
  return useTransform(value, [0, 1], [`${start / 10}rem`, `${end / 10}rem`]);
}

// DESKTOP COMPONENT
function DesktopParallaxWrapper({ className, children, positions, offset = ["start end", "end start"] }: ParallaxWrapperProps) {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({ target: ref, offset });
  const { start, end } = positions;
  const y = useParallax(scrollYProgress, start, end !== undefined ? end : 0);

  return (
    <ParallaxWrapperEl className={className} style={{ y }} ref={ref}>
      {children}
    </ParallaxWrapperEl>
  )

}

// MOBILE COMPONENT
function MobileParallaxWrapper({ className, children, positions, offset = ["start end", "end start"], offsetMobile }: ParallaxWrapperProps) {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({ target: ref, offset: offsetMobile || offset });

  const { start, end, startMobile, endMobile } = positions;
  const startY = startMobile !== undefined ? startMobile : start;
  const endY = endMobile !== undefined ? endMobile : end !== undefined ? end : 0;
  const y = useParallax(scrollYProgress, startY, endY);

  return (
    <ParallaxWrapperEl className={className} style={{ y }} ref={ref}>
      {children}
    </ParallaxWrapperEl>
  )

}


// COMPONENT
export default function ParallaxWrapper(props: ParallaxWrapperProps) {
  const { state } = useApp();
  const { isMobile } = state;

  // NOTE: can't have conditional hooks, so swapping out components based on mobile
  const Tag = isMobile ? MobileParallaxWrapper : DesktopParallaxWrapper;

  return <Tag {...props} />

}
