import { PrismicLink, PagesType } from "./CustomTypes.types";


export function getUrlFromPrimicLink(link: PrismicLink) {

  switch (link.type) {
    case PagesType.DROP:
      return `/drop/${link.slug}`;
    case PagesType.HOME:
      return `/`;
  }

}