import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AnimatedLinkProps } from './AnimatedLink.types'

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: 0 0;
  }

  .has-hover & {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
`

const StyledReversedLink = styled(StyledLink)`
  &:after {
    transform: scaleX(1);
    transform-origin: 100% 0;
  }

  .has-hover & {
    &:hover {
      &:after {
        transform: scaleX(0);
      }
    }
  }
`

export default function AnimatedLink({ className, children, url, onClick, target = '_self', reversed = false }: AnimatedLinkProps) {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  }

  const Tag = reversed ? StyledReversedLink : StyledLink;

  return (
    <Tag
      className={className}
      to={url}
      onClick={handleClick}
      target={target}
      rel="noreferrer"
    >
      {children}
    </Tag>
  )
}
