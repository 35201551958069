import React from "react";

import styled from "styled-components";
import ShowcaseMedia from "../ShowcaseMedia";
import ThemeWrapper from "../ThemeWrapper";
import { Theme } from "../ThemeWrapper/ThemeWrapper.types";
import TransparentVideo from "../TransparentVideo";
import { PrimaryHeroProps, PrimaryHeroTypes } from "./PrimaryHero.types";

const PrimaryHeroWrapper = styled(ThemeWrapper)`
  position: relative;
  margin-top: 6rem;

  &:before {
    content: '';
    display: block;
    padding-bottom: 140%;
  }
  
  @media screen and (min-width: 1024px) {
    margin-top: 14rem;
    &:before {
      padding-bottom: 50%;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledTransparentVideo = styled(TransparentVideo)`
  flex: 0 0 200%;
  
  @media screen and (min-width: 1024px) {
    flex: 0 0 72%;
  }
`

const Content = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {

  }
`;

const MediaWrapper = styled.div`
  position: relative;
  width: 28.4rem;
  height: 28.4rem;

  @media screen and (min-width: 1024px) {
    width: 51.2rem;
    height: 29rem;
  }
`;

const PrimaryHero = ({ videoUrl, imageUrl, backgroundVideo, type = PrimaryHeroTypes.IMAGE, button }: PrimaryHeroProps) => {

  return (
    <PrimaryHeroWrapper theme={Theme.DARK}>
      <Content>
        <Background>
          <StyledTransparentVideo {...backgroundVideo} />
        </Background>
        <MediaWrapper>
          <ShowcaseMedia type={type} videoUrl={videoUrl || ''} imageUrl={imageUrl || ''} button={button} />
        </MediaWrapper>
      </Content>
    </PrimaryHeroWrapper>
  );
};

export default PrimaryHero;
