import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

// import { animate, motion, useMotionValue } from "framer-motion";
import { CarouselProps, CarouselArrayItem } from "./Carousel.types";

import CarouselItem from "./CarouselItem";
import { getWidthForAspectRatio } from "./CarouselUtils";
import Typography from "../Typography";
import ArrowButton from "./ArrowButton";
import CarouselFooter from "./CarouselFooter";
import Spacer from "../layout/Spacer";
import { useApp } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import ThemeWrapper from "../ThemeWrapper";
import { Theme } from "../ThemeWrapper/ThemeWrapper.types";
import ParallaxWrapper from "../ParallaxWrapper";

gsap.registerPlugin(Draggable);

const CarouselEl = styled(ThemeWrapper)`
  width: 100%;
  overflow: hidden;
  padding: 8rem 0;

  @media screen and (min-width: 1024px) {
    padding: 15rem 0;
  }
`;

const TitleWrapper = styled.div.attrs((props: { isSlider: boolean }) => props)`
  width: 90%;
  margin-bottom: 3.6rem;

  @media screen and (min-width: 1024px) {
    width: 80rem;
    margin-bottom: ${(props) => (props.isSlider ? -0.65 : 4)}rem;
  }
`;

const Slider = styled(ParallaxWrapper)`
  width: 76%;
  margin: auto;
`;

const List = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  & > * {
    margin: 0 0 0 1.6rem;
  }

  @media screen and (min-width: 1024px) {
    & > * {
      margin: 0 0 0 2rem;
    }
  }
`;

const StyledCarouselFooter = styled(CarouselFooter)`
  width: 76%;
  margin: auto;
`;

const Carousel = ({
  items,
  title,
  footer,
  theme = Theme.LIGHT,
}: CarouselProps) => {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  // HOOKS
  const { width, height } = useWindowSize();

  // STATE
  const [isSlider] = useState(items?.length > 1 || false);
  const [originalItemWidth, setOriginalItemWidth] = useState(0);
  const [duplicatedItems, setDuplicatedItems] = useState<CarouselArrayItem[]>(
    []
  );

  // DOM REFS
  const indexSpanRef = useRef<HTMLSpanElement>(null);
  const sliderRef = useRef<HTMLUListElement>(null);
  const sliderListItemsRef = useRef<HTMLLIElement[]>([]);
  const currentIndex = useRef(0);
  const previousIndex = useRef(0);

  // OTHER REFS
  const isAnimating = useRef(false);
  const containerWidth = useRef(0);
  const margin = useRef(0);
  const sliderPos = useRef({ x: 0, y: 0 });
  const draggableRef = useRef<Draggable>();

  const distance = useRef(-originalItemWidth);

  // FRAMER
  // const sliderXPos = useMotionValue(-originalItemWidth);

  // METHODS
  const uppdateSliderPosition = useCallback(
    (x: number) => {
      if (!sliderRef.current) return;

      if (x > 0) {
        sliderPos.current.x = -originalItemWidth;
      } else if (x < -originalItemWidth) {
        sliderPos.current.x = 0;
      } else {
        sliderPos.current.x = x;
      }

      gsap.set(sliderRef.current, {
        x: sliderPos.current.x - originalItemWidth,
      });
      // sliderListRef.current.style.transform = `translate(${x}, 0)`;
    },
    [originalItemWidth]
  );

  const animateToIndex = useCallback(
    (nextIndex: number) => {
      if (previousIndex.current === nextIndex || items?.length === 1) return;

      let difference = 0;
      let activeItemIndex = 0;

      if (currentIndex.current > previousIndex.current) {
        difference =
          duplicatedItems[previousIndex.current].width +
          containerWidth.current * margin.current;
        distance.current -= difference;
        activeItemIndex = items.length + currentIndex.current - 1;
      } else {
        difference =
          duplicatedItems[currentIndex.current].width +
          containerWidth.current * margin.current;
        distance.current += difference;
        activeItemIndex = items.length + previousIndex.current;
      }

      const animObj = { x: sliderPos.current.x };
      const targetX = distance.current;
      gsap.to(animObj, {
        x: targetX,
        onUpdate: () => {
          uppdateSliderPosition(animObj.x);
        },
        duration: 1,
        ease: "Power2.easeInOut",
      });

      sliderListItemsRef.current.forEach((item, i) => {
        const x = difference * 0.25 * 0.15 * (i - activeItemIndex);
        gsap.to(item, {
          x,
          yoyo: true,
          duration: 0.45,
          delay: 0.05,
          repeat: 1,
          ease: "Power2.easeInOut",
        });
      });

      if (indexSpanRef.current) {
        indexSpanRef.current.innerHTML =
          (currentIndex.current % items.length) + 1 + "";
      }

      previousIndex.current = nextIndex;
    },
    [duplicatedItems, items, uppdateSliderPosition]
  );

  const next = useCallback(() => {
    if (currentIndex.current + 1 > items.length) {
      uppdateSliderPosition(0);
      distance.current = 0;
      previousIndex.current = 0;
      currentIndex.current = 1;
    } else {
      currentIndex.current = currentIndex.current + 1;
    }

    animateToIndex(currentIndex.current);
  }, [animateToIndex, items, uppdateSliderPosition]);

  const previous = useCallback(() => {
    if (currentIndex.current - 1 < 0) {
      uppdateSliderPosition(-originalItemWidth);
      distance.current = -originalItemWidth;
      previousIndex.current = items.length;
      currentIndex.current = items.length - 1;
    } else {
      currentIndex.current = currentIndex.current - 1;
    }
    animateToIndex(currentIndex.current);
  }, [animateToIndex, items, originalItemWidth, uppdateSliderPosition]);

  // EVENT HANDLERS
  function handleNextClicked() {
    if (isAnimating.current) return;
    next();
  }

  function handlePrevClicked() {
    if (isAnimating.current) return;
    previous();
  }

  // HOOKS
  useEffect(() => {
    function setupCarousel() {
      if (!sliderRef.current) return;

      if (!isSlider) {
        setDuplicatedItems(items);
        return;
      }

      const { width } = sliderRef.current.getBoundingClientRect();
      containerWidth.current = width;

      margin.current = 0.1; // 10%

      const tempItems = [...items];
      let size = 0;
      tempItems.forEach((item) => {
        const itemWidth = getWidthForAspectRatio(item.ratio, isMobile) / 100; // In percentage of container
        item.width = width * itemWidth; // Without margin
        size += width * (itemWidth + margin.current); // Total size of wrapper includes margin;
      });

      setOriginalItemWidth(size);
      setDuplicatedItems([...tempItems, ...tempItems, ...tempItems]);

      previousIndex.current = 0;
      currentIndex.current = 0;
    }

    setupCarousel();
  }, [width, height, isSlider, items, isMobile]);

  useEffect(() => {
    function handleDragStart() {
      if (!draggableRef.current) return;

      // @ts-ignore: next-line;
      const direction = draggableRef.current.getDirection();
      if (direction === "left") {
        next();
      } else {
        previous();
      }
    }

    var proxy = document.createElement("div");

    // @ts-ignore: next-line;
    draggableRef.current = Draggable.create(proxy, {
      type: "x",
      minimumMovement: 10,
      trigger: sliderRef.current,
      onDragStart: handleDragStart,
    })[0];

    const d = draggableRef.current;

    uppdateSliderPosition(0);

    return () => {
      if (d) d?.kill();
    };
  }, [next, previous, uppdateSliderPosition]);

  return (
    <CarouselEl theme={theme}>
      <TitleWrapper isSlider={isSlider}>
        <Typography variant={isMobile ? "h5" : "h2"} html={title} />
      </TitleWrapper>
      <Slider
        positions={{
          start: 60,
          startMobile: 50,
          end: 0,
          endMobile: 0,
        }}
        offset={["start end", "start 30%"]}
      >
        <List ref={sliderRef}>
          {duplicatedItems?.map((item, i) => (
            <CarouselItem
              key={i}
              ref={(ref: HTMLLIElement) =>
                (sliderListItemsRef.current[i] = ref)
              }
              item={item}
              style={{ marginRight: `${margin.current * 100}%` }}
            />
          ))}
        </List>
        {isSlider && (
          <Controls>
            <Typography variant="h8" alignment="left">
              <span ref={indexSpanRef}>1</span> / {items.length}
            </Typography>
            <ButtonsWrapper>
              <ArrowButton type="left" onClick={handlePrevClicked} />
              <ArrowButton type="right" onClick={handleNextClicked} />
            </ButtonsWrapper>
          </Controls>
        )}
      </Slider>
      <Spacer size={6} />
      <StyledCarouselFooter {...footer} />
    </CarouselEl>
  );
};

export default Carousel;
