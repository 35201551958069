import { MotionStyle } from "framer-motion";
import { PrismicImage } from "../../prismic/CustomTypes.types";

export enum SocialTypes {
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
}

export enum AspectRatios {
  SQUARE = '1:1',
  LANDSCAPE_16_9 = '16:9',
  PORTRAIT_9_16 = '9:16',
  LANDSCAPE_4_3 = '4:3',
  PORTRAIT_3_4 = '3:4'
}

export interface CarouselArrayItem {
  type: string;
  image: PrismicImage;
  ratio: AspectRatios;
  video_url?: {
    link_type: string;
    url: string;
  };
  width: number;
}

export interface CarouselProps {
  title: string,
  items: CarouselArrayItem[];
  footer: CarouselFooterProps;
  theme?: string;
}


export interface CarouselItem {
  // NOTE: options is of type ControlsAnimationDefinition, but this is not exported by Framer.
  animate: (options: {}) => void;
}

export interface CarouselItemProps {
  item: CarouselArrayItem;
  style: MotionStyle
}

interface SocialIcon {
  type: SocialTypes,
  url: string
}


export interface CarouselFooterProps {
  className?: string,
  title?: string,
  body?: string[],
  link?: {
    label: string,
    url: string,
  },
  socials?: SocialIcon[]
}