import { usePrismicDocumentByUID, useSinglePrismicDocument } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../components/Header';
import PageBackground from '../components/PageBackground';
import PageFooter from '../components/PageFooter';
import { ComponentMapper } from '../prismic/ComponentMapper';
import FourOhFour from './FourOhFour';

export default function DropPage() {
  const { uid } = useParams();

  const [header, headerState] = useSinglePrismicDocument<PrismicDocument>("header");
  const [page, pageState] = usePrismicDocumentByUID("droppage", uid || '', {
    fetchLinks: [
      'product.name',
      'product.color',
      'product.price',
      'product.images',
      'product.sold_out',
      'product.link_url'
    ]
  });
  const [footer, footerState] = useSinglePrismicDocument<PrismicDocument>("footer");

  const notFound = pageState.state === "failed" || footerState.state === "failed" || headerState.state === "failed";

  useEffect(() => {
    if (notFound) {
      console.warn(
        "Page document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [notFound]);


  if (header && page && footer) {
    return (
      <PageBackground>
        <Header
          logoUrl="/"
          link={{
            label: header.data.link_label_drop_page,
            labelMobile: header.data.link_label_drop_page_mobile,
            url: "/"
          }}
          ribbon={header.data.sales_ribbon_active ? {
            text: header.data.sales_ribbon_text,
            link: {
              label: header.data.sales_ribbon_link_label,
              url: header.data.sales_ribbon_link_url
            }
          } : undefined}
        />
        <ComponentMapper components={page.data.body} />
        <PageFooter
          img={{
            url: ''
          }}
          socials={footer.data.socials}
          copyright={footer.data.copyright}
          link={{
            url: footer.data.link_url.url,
            label: footer.data.link_label,
            target: footer.data.link_url.target
          }}
        />
      </PageBackground>
    );
  } else if (notFound) {
    return <FourOhFour />;
  }

  return null;
}
