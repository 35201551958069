import React, { useEffect, useState, Fragment } from "react";

import styled from "styled-components";
import CollaborationDrop from "../layout/CollaborationDrop";
import Typography from "../Typography";
import Image from "../Image/Image";
import { SecondaryHeroProps } from "./SecondaryHero.types";
import CountDown from "../CountDown";
import Marquee from "react-fast-marquee";
import { useApp } from "../../context/AppContext";
import { Link } from "react-router-dom";
import AnimatedLink from "../AnimatedLink";

const SecondaryHeroWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SecondaryHeroContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SecondaryHeroSizer = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-bottom: 198%;
  }

  @media screen and (min-width: 1024px) {
    &:before {
      padding-bottom: 56.25%;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;

  max-width: 11.2rem;
  max-height: 11.2rem;
  right: 2.5rem;
  bottom: 9.5rem;

  @media screen and (min-width: 1024px) {
    max-width: 18.1rem;
    max-height: 18.1rem;
    right: 10.5rem;
    bottom: 10.5rem;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  left: 10.4rem;
  bottom: 10.2rem;
  position: absolute;
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  margin-right: auto;
`;

const CountDownWrapper = styled.div`
  left: 50%;
  bottom: 25%;
  transform: translateX(-50%);
  position: absolute;

  @media screen and (min-width: 1024px) {
    left: calc(50% + 0.15rem);
    bottom: 16%;
  }
`;

const RibbonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.6rem;

  background: url(/images/grain.png) repeat, var(--color-white);
  background-blend-mode: multiply, normal;
  filter: invert(1);
`;

const DateWrapper = styled.div`
  margin-left: 4rem;
  margin-right: 4rem;
`;

const SecondaryHero = ({
  className,
  images,
  title,
  launchDate,
  overlayTextColor,
  url,
  links,
  logo,
  showCountdown,
  showRibbon,
  showSingleImageOnMobile = true,
}: SecondaryHeroProps) => {
  const { state } = useApp();
  const { isMobile } = state;

  const [prettyLaunchData, setPrettyLaunchDate] = useState("");

  const handleLinkClicked = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    let href = e.currentTarget.getAttribute("href");

    if (!href) return;
    href = href.replaceAll("#/", "");

    const element = document.querySelector(`[data-anchor="${href}"]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn("No 360 product with product_slug :: ", href);
    }
  };

  useEffect(() => {
    if (launchDate) {
      setPrettyLaunchDate(
        launchDate.toISOString().split("T")[0].replaceAll("-", " - ")
      );
    }
  }, [launchDate]);

  return (
    <SecondaryHeroWrapper className={className}>
      <SecondaryHeroSizer>
        <SecondaryHeroContent>
          <Link to={url || ""}>
            <CollaborationDrop
              images={images}
              collaboratorName={title}
              useOverlay={showCountdown || showRibbon}
              showSingleImageOnMobile={showSingleImageOnMobile}
            />
          </Link>
          {showCountdown && launchDate && (
            <CountDownWrapper style={{ color: overlayTextColor }}>
              {isMobile ? (
                <CountDown type="small" launchDate={launchDate} />
              ) : (
                <CountDown type="medium" launchDate={launchDate} />
              )}
            </CountDownWrapper>
          )}

          {logo && (
            <LogoWrapper>
              <Image src={logo.url} alt={logo.alt || ""} objectFit="contain" />
            </LogoWrapper>
          )}

          {links && !isMobile && (
            <LinksWrapper style={{ color: overlayTextColor }}>
              {links.map(({ label, url }, index) => (
                <StyledAnimatedLink
                  url={`/#/${url}`}
                  key={`anchor-${index}`}
                  onClick={handleLinkClicked}
                >
                  <Typography variant="h8" color={overlayTextColor || "white"}>
                    {label}
                  </Typography>
                </StyledAnimatedLink>
              ))}
            </LinksWrapper>
          )}
        </SecondaryHeroContent>
      </SecondaryHeroSizer>
      {launchDate && showRibbon && (
        <RibbonWrapper>
          <Marquee play={true} gradient={false} speed={75}>
            {Array.from({ length: 5 }).map((n, i) => (
              <Fragment key={i}>
                <Typography
                  variant={isMobile ? "h9" : "h8"}
                  color="black"
                  alignment="center"
                >
                  {"LAUNCH DATE"}
                </Typography>

                <DateWrapper>
                  <Typography
                    variant={isMobile ? "h9" : "h8"}
                    color="black"
                    alignment="center"
                  >
                    {prettyLaunchData}
                  </Typography>
                </DateWrapper>
              </Fragment>
            ))}
          </Marquee>
        </RibbonWrapper>
      )}
    </SecondaryHeroWrapper>
  );
};

export default SecondaryHero;
