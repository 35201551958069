import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { useApp } from "../../context/AppContext";
import Spacer from "../layout/Spacer";
import TickerButton from "../TickerButton";
import Typography from "../Typography";
import { ErrorPageProps } from "./ErrorPage.types";

const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;

  background: url(/images/gradient-background-mobile.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply, normal;

  @media screen and (min-width: 1024px) {
    background: url(/images/gradient-background.jpg);
  }
`;

const ImageWrapper = styled.div`
  width: 11.4rem;
  height: 4.1rem;

  @media screen and (min-width: 1024px) {
    width: 17.3rem;
    height: 6.1rem;
  }
`;

const BodyWrapper = styled.div`
  max-width: 60.5rem;
  text-align: center;

  padding-left: 3.8rem;
  padding-right: 3.8rem;

  @media screen and (min-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;

const StyledBodyTypography = styled(Typography)`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`

const ErrorPage = ({ title, body, link }: ErrorPageProps) => {
  const { state } = useApp();
  const { isMobile } = state;

  return (
    <ErrorPageWrapper>
      <Spacer size={9} />
      <ImageWrapper>
        <Link to="/">
          <img
            style={{ width: "100%", height: "100%" }}
            src="../../images/buffalo-logo.png"
            alt="Buffalo logo"
          />
        </Link>
      </ImageWrapper>

      <ContentWrapper>
        <Typography variant={isMobile ? "h2" : "h0"} color="white">
          {title}
        </Typography>
        <Spacer size={3} />
        <BodyWrapper>
          {body?.map((p, i) => (
            <StyledBodyTypography key={i} variant={isMobile ? "body4" : "body2"} color="white" alignment="center">
              {p}
            </StyledBodyTypography>
          ))}
        </BodyWrapper>
        <Spacer size={isMobile ? 8 : 14} />
        <TickerButton
          width={isMobile ? "20rem" : "35.1rem"}
          height="3.5rem"
          onClick={() => { }}
          text={link.label}
        />
      </ContentWrapper>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
