import { motion } from "framer-motion";
import React from "react";

import styled from "styled-components";
import Typography from "../Typography";
import { DigitProps } from "./Digit.types";

const DigitWrapper = styled.div.attrs((props: { value: string, correctOne: boolean }) => props)`
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.value === "1" && props.correctOne ? "flex-end" : "center"};
  position: relative;
`;

const StyledMotionDiv = styled(motion.div)`
  top: 0;
  position: absolute;
`;

const Digit = ({
  value,
  headingType = "h2",
  color = "white",
  duration = 0.5,
  correctOne = false,
}: DigitProps) => {
  return (
    <DigitWrapper value={value} correctOne={correctOne}>
      <StyledMotionDiv
        key={value}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        <Typography variant={headingType} color={color}>
          {value}
        </Typography>
      </StyledMotionDiv>
    </DigitWrapper>
  );
};

export default Digit;
