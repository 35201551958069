import React from 'react'
import styled from 'styled-components';
import { useApp } from '../../../context/AppContext';
import ThemeWrapper from '../../ThemeWrapper';
import Typography from '../../Typography';
import { TextOnlyProps } from './TextOnly.types'

const TextOnlyWrapper = styled(ThemeWrapper)`
  padding: 4rem 0 8rem;
  
  @media screen and (min-width: 1024px) {
    padding: 5.5rem 10rem 8rem;
  }
`;

const StyledTitle = styled(Typography)`
  margin-bottom: 3rem;

  @media screen and (min-width: 1024px) {
    width: 64rem;
    margin-bottom: 6.4rem;
  }
`

const StyledBody = styled(Typography)`
  padding: 0 4rem;
  
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: 33.6rem;
  }
`

export default function TextOnly({ className, theme, title, body }: TextOnlyProps) {
  const { state } = useApp();
  const { isMobile } = state;

  return (
    <TextOnlyWrapper className={className} theme={theme}>
      <StyledTitle variant={isMobile ? "h5" : "h2"} >{title}</StyledTitle>
      {body?.map((p, i) => (
        <StyledBody key={i} variant={isMobile ? "body4" : "body2"} >
          {p}
        </StyledBody>
      ))}
    </TextOnlyWrapper>
  )
}
