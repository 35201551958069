import { ReactNode } from "react";

export enum Theme {
  DARK = "dark",
  LIGHT = "light",
}

export interface ThemeWrapperProps {
  theme?: Theme;
  className?: string;
  children?: ReactNode;
}