import React from "react";

import styled from "styled-components";
import { ImageProps } from "./Image.types";

const ImageWrapper = styled.div.attrs(
  (props: { width: string; height: string }) => props
) <any>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const StyledImage = styled.img.attrs(
  (props: { objectFit: string }) => props
) <any>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.objectFit};
`;

const Image = ({
  className,
  width = "100%",
  height = "100%",
  src,
  alt,
  objectFit = "cover",
  draggable = false,
}: ImageProps) => {
  return (
    <ImageWrapper className={className} width={width} height={height}>
      <StyledImage
        src={src}
        alt={alt}
        objectFit={objectFit}
        draggable={draggable}
      />
    </ImageWrapper>
  );
};

export default Image;
