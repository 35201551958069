import React, { forwardRef } from "react";
import styled from "styled-components";

import {
  CarouselItemProps,
  CarouselItem as CarouselItemType,
} from "./Carousel.types";
import {
  getAspectRatioInPercentage,
  getWidthForAspectRatio,
} from "./CarouselUtils";
import { useApp } from "../../context/AppContext";
import ShowcaseMedia from "../ShowcaseMedia";

const ListItem = styled.li`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  top: 0;
  left: 0;
`;

const ItemWrapper = styled.div.attrs((props: { ratio: number }) => props)`
  position: relative;
  display: block;
  width: 100%;
  margin-top: auto;

  &:after {
    content: "";
    width: 100%;
    padding-bottom: ${(props) => props.ratio}%;
    display: block;
  }
`;

const StyledShowCaseMedia = styled(ShowcaseMedia)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dedede;
  object-fit: cover;
  user-select: none;
`;

const CarouselItem = forwardRef<CarouselItemType, CarouselItemProps>(
  ({ item, style }, ref) => {
    const { state } = useApp();
    const { isMobile } = state;

    const ShowCaseMediaType = item.video_url?.url ? "video" : "image";
    const width: string = `${getWidthForAspectRatio(item.ratio, isMobile)}%`;

    return (
      // @ts-ignore: next-line;
      <ListItem style={{ width, ...style }} ref={ref}>
        <ItemWrapper ratio={getAspectRatioInPercentage(item.ratio, isMobile)}>
          <StyledShowCaseMedia
            type={ShowCaseMediaType}
            imageUrl={
              isMobile && item.image.mobile!
                ? item.image.mobile!.url
                : item.image.url
            }
            videoUrl={item.video_url?.url || ""}
          />
        </ItemWrapper>
      </ListItem>
    );
  }
);

export default CarouselItem;
