import { Node } from 'gl-react';
import { Surface } from 'gl-react-dom';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useWindowSize from '../../hooks/useWindowSize';
import shaders from './Shaders';
import { TransparentVideoProps } from './TransparentVideo.types'


const TransparentVideoEl = styled.div.attrs(
  (props: { aspectRatio: number }) => ({
    aspectRatio: props.aspectRatio || 56.25
  })
)`
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-bottom: ${(props) => props.aspectRatio}%;
  }
`;

const Sizer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const CanvasWrapper = styled.div`
  height: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
`


export default function TransparentVideo({ className, url, videoDimensions = { width: 1920, height: 1080 } }: TransparentVideoProps) {
  // HOOKS
  const { width, height } = useWindowSize();

  // STATE
  const [size, setSize] = useState({ w: 0, h: 0 });
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  // REFS
  const rafRef = useRef(0);
  const lastTime = useRef(0);

  // DOM REFS
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>();
  const sizerRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<Node>(null);

  // CONSTS
  const ratio = videoDimensions.height / videoDimensions.width * 100;

  // METHODS
  function handleClick() {
    videoRef.current?.play();
  }

  // HOOKS
  useEffect(() => {
    const tick = () => {
      rafRef.current = window.requestAnimationFrame(tick);

      if (!videoRef.current || !nodeRef.current) return;
      const currentTime = videoRef.current.currentTime;
      if (lastTime.current !== currentTime) {
        if (!videoIsPlaying) setVideoIsPlaying(true);
        lastTime.current = currentTime;

        // NOTE: no types available, or types are incorrect.
        // @ts-ignore: next-line
        nodeRef.current.redraw();
      }
    }

    tick();

    return () => {
      if (rafRef.current) window.cancelAnimationFrame(rafRef.current)
    }
  }, [videoIsPlaying])

  useEffect(() => {
    if (!sizerRef.current) return;

    const { width: sizerWidth, height: sizerHeight } = sizerRef.current.getBoundingClientRect();
    setSize({ w: sizerWidth, h: sizerHeight });

  }, [width, height])

  useEffect(() => {
    videoRef.current = videoWrapperRef.current?.querySelector('video') as HTMLVideoElement;
  }, [])

  return (
    <TransparentVideoEl className={className} onClick={handleClick} aspectRatio={ratio}>
      <Sizer ref={sizerRef}>
        {/* NOTE: dangerlously set innerHTML because muted is not propery set by react on video tag */}
        <VideoWrapper
          ref={videoWrapperRef}
          dangerouslySetInnerHTML={{
            __html: `
              <video
                style="opacity: 0.05; position: absolute; width: 1px; height: 1px; left: 0; right: 0; margin: auto;"
                loop
                muted
                autoplay
                playsinline
                preload="metadata"
              >
                <source src="${url}" type="video/mp4" />
              </video>
            `
          }}
        >

        </VideoWrapper>
        <CanvasWrapper>
          <Surface width={size.w} height={size.h}>
            <Node ref={nodeRef} shader={shaders.video} uniforms={{
              texture: () => videoRef.current
            }}>
            </Node>
          </Surface>
        </CanvasWrapper>
      </Sizer>
    </TransparentVideoEl>
  )
}
