import React, { useRef, useState } from "react";

import styled from "styled-components";
import { ShowcaseMediaProps } from "./ShowcaseMedia.types";
import ReactPlayer from "react-player";
import { motion, useAnimation } from "framer-motion";
import TickerButton from "../TickerButton";
import { PrimaryHeroTypes } from "../PrimaryHero/PrimaryHero.types";

const ShowcaseMediaWrapper = styled.div`
  overflow: hidden;
`;

const VideoWrapper = styled(motion.div) <any>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: black;
`;

const VideoRoot = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
`;

const PosterImage = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Image = styled.img.attrs((props: { objectFit: string }) => props)`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.objectFit};
`;

const TickerButtonWrapper = styled.div`
  position: absolute;
  color: white;
`;

const ShowcaseMedia = ({
  className,
  type,
  videoUrl,
  imageUrl,
  objectFit = "cover",
  button,
  ...props
}: ShowcaseMediaProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const animationControls = useAnimation();
  const videoWrapperRef = useRef<HTMLElement>();

  function handlePlayButtonClicked() {
    setIsPlaying(true);
  }

  function handleVideoPlay() {
    setIsPlaying(true);
  }

  function handleVideoEnded() {
    setIsPlaying(false);
  }

  function handleVideoPause() {
    setIsPlaying(false);
  }

  return (
    <ShowcaseMediaWrapper
      className={className}
      style={{
        width: props.width != null ? props.width : "100%",
        height: props.height != null ? props.height : "100%",
      }}
    >
      {type === PrimaryHeroTypes.VIDEO && (
        <VideoWrapper
          ref={videoWrapperRef}
          animate={animationControls}
        >
          <VideoRoot
            id="video-player"
            style={{ opacity: isPlaying ? 1 : 0 }}
            url={videoUrl}
            width={null}
            height={null}
            playing={isPlaying}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onEnded={handleVideoEnded}
            controls
          />
          {!isPlaying && imageUrl && <PosterImage src={imageUrl} alt="Showcase image" draggable={false} />}
          {!isPlaying && (
            <TickerButtonWrapper>
              <TickerButton
                onClick={handlePlayButtonClicked}
                text={button?.label || "Play Video"}
              />
            </TickerButtonWrapper>
          )}
        </VideoWrapper>
      )}
      {type === PrimaryHeroTypes.IMAGE && (
        <Image src={imageUrl} alt="Showcase image" objectFit={objectFit} draggable={false} />
      )}
    </ShowcaseMediaWrapper>
  );
};

export default ShowcaseMedia;
