export enum PrimaryHeroTypes {
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface PrimaryHeroProps {
  type: PrimaryHeroTypes
  videoUrl?: string;
  imageUrl?: string;
  button?: {
    label: string;
  };
  backgroundVideo: {
    url: string,
    videoDimensions: {
      width: number,
      height: number,
    }
  }
}
