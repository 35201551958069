import React from "react";

import styled from "styled-components";
import { useApp } from "../../../context/AppContext";
import Image from "../../Image";
import ParallaxWrapper from "../../ParallaxWrapper";
import ThemeWrapper from "../../ThemeWrapper";
import Typography from "../../Typography";
import Spacer from "../Spacer";

import { SplitTextBProps } from "./SplitTextB.types";

const SplitTextBWrapper = styled(ThemeWrapper)`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 8rem;

  @media screen and (min-width: 1024px) {
    padding-left: 10.3rem;
    padding-top: 5.5rem;
    padding-bottom: 12.7rem;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 66.7rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 3.8rem;
  
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: -0.6rem;
    padding-left: 0;
  }
`;

const BodyWrapper = styled.div`
  margin-top: 5rem;
  margin-top: 3rem;
  width: 28.5rem;
  
  @media screen and (min-width: 1024px) {
    margin-top: 6.4rem;
    width: 100%;
    flex: 0 0 33.6rem
  }
`;

const StyledBodyTypograhy = styled(Typography)`

  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const ImageWrapper = styled.div`
  height: 18.3rem;
  width: 34.2rem;

  @media screen and (min-width: 1024px) {
    height: 52.2rem;
    flex: 0 0 93.6rem;
  }
`;

const SplitTextB = ({ theme = "light", image, title, body }: SplitTextBProps) => {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  return (
    <SplitTextBWrapper theme={theme}>
      <TitleWrapper>
        <Typography variant={isMobile ? "h5" : "h2"}>
          {title}
        </Typography>
      </TitleWrapper>
      {isMobile && <Spacer size={8} />}
      <ContentWrapper>
        <ImageWrapper>
          <ParallaxWrapper
            positions={{
              start: 40,
              startMobile: 40,
              end: 0,
              endMobile: 0
            }}
            offset={['start end', 'start 50%']}
          >
            {image && <Image src={image.url} alt={image.alt || ''} objectFit="cover" />}
          </ParallaxWrapper>
        </ImageWrapper>
        <BodyWrapper>
          {body?.map((p, i) => (
            <StyledBodyTypograhy key={i} variant={isMobile ? "body4" : "body2"} >
              {p}
            </StyledBodyTypograhy>
          ))}
        </BodyWrapper>
      </ContentWrapper>
    </SplitTextBWrapper>
  );
};

export default SplitTextB;
