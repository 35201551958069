import * as React from "react";

const Instagram = ({
  width = 22,
  height = 22,
  color = "currentColor",
  ...rest
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.946 5.448C7.84058 5.448 5.33569 7.95288 5.33569 11.0583C5.33569 14.1638 7.84058 16.6687 10.946 16.6687C14.0515 16.6687 16.5564 14.1638 16.5564 11.0583C16.5564 7.95288 14.0515 5.448 10.946 5.448ZM10.946 14.7058C8.93921 14.7058 7.29858 13.0701 7.29858 11.0583C7.29858 9.04663 8.93433 7.41089 10.946 7.41089C12.9578 7.41089 14.5935 9.04663 14.5935 11.0583C14.5935 13.0701 12.9529 14.7058 10.946 14.7058ZM18.0945 5.21851C18.0945 5.94604 17.5085 6.5271 16.7859 6.5271C16.0584 6.5271 15.4773 5.94116 15.4773 5.21851C15.4773 4.49585 16.0632 3.90991 16.7859 3.90991C17.5085 3.90991 18.0945 4.49585 18.0945 5.21851ZM21.8103 6.54663C21.7273 4.7937 21.3269 3.24097 20.0427 1.96167C18.7634 0.682373 17.2107 0.281982 15.4578 0.194092C13.6511 0.0915527 8.23608 0.0915527 6.42944 0.194092C4.6814 0.2771 3.12866 0.67749 1.84448 1.95679C0.560303 3.23608 0.164795 4.78882 0.0769043 6.54175C-0.0256348 8.34839 -0.0256348 13.7634 0.0769043 15.5701C0.159912 17.323 0.560303 18.8757 1.84448 20.155C3.12866 21.4343 4.67651 21.8347 6.42944 21.9226C8.23608 22.0251 13.6511 22.0251 15.4578 21.9226C17.2107 21.8396 18.7634 21.4392 20.0427 20.155C21.322 18.8757 21.7224 17.323 21.8103 15.5701C21.9128 13.7634 21.9128 8.35327 21.8103 6.54663ZM19.4763 17.5085C19.0955 18.4656 18.3582 19.2029 17.3962 19.5886C15.9558 20.1599 12.5378 20.0281 10.946 20.0281C9.35425 20.0281 5.9314 20.155 4.49585 19.5886C3.53882 19.2078 2.80151 18.4705 2.41577 17.5085C1.84448 16.0681 1.97632 12.6501 1.97632 11.0583C1.97632 9.46655 1.84937 6.0437 2.41577 4.60815C2.79663 3.65112 3.53394 2.91382 4.49585 2.52808C5.93628 1.95679 9.35425 2.08862 10.946 2.08862C12.5378 2.08862 15.9607 1.96167 17.3962 2.52808C18.3533 2.90894 19.0906 3.64624 19.4763 4.60815C20.0476 6.04858 19.9158 9.46655 19.9158 11.0583C19.9158 12.6501 20.0476 16.073 19.4763 17.5085Z"
        fill={color}
      />
    </svg>
  );
};

export default Instagram;
