import { motion, useAnimation, useAnimationControls, useMotionValueEvent, useScroll } from "framer-motion";
import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useApp } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import AnimatedLink from "../AnimatedLink";
import SalesRibbon from "../SalesRibbon";
import Typography from "../Typography";
import { HeaderProps } from "./Header.types";

const HeaderWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  z-index: 10;
  pointer-events: none;
`;

const HeaderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 8.5rem;
  padding-left: 1.8rem;
  padding-right: 2rem;
  background-color: transparent;
  

  @media screen and (min-width: 1024px) {
    justify-content: center;
    height: 12rem;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ArrowSpan = styled.span`
  margin-right: 1.2em;
`

const ImageWrapper = styled.div`
  width: 11.4rem;
  height: 4.1rem;
  pointer-events: initial;

  @media screen and (min-width: 1024px) {
    width: 17.3rem;
    height: 6.1rem;
  }
`;

const StyledSalesRibbon = styled(SalesRibbon)`
  pointer-events: initial;
`

const MotionImage = styled(motion.img)``

const BackButtonWrapper = styled(motion.div)`
  position: fixed;
  width: 50%;
  top: 9.2rem;
  left: 2rem;
  z-index: 10;

  color: white;
  mix-blend-mode: difference;
  will-change: transform;


  @media screen and (min-width: 1024px) {
    position: fixed;
    left: 0;
    top: 6.5rem;
    padding-top: 3rem;
    padding-left: 8.1rem;
  }
`;

const BackButtonContent = styled(motion.div)`
  position: relative;
  color: white;

  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 3rem;
    padding-left: 8.1rem;
  }
`;

const Header = ({ logoUrl, link, ribbon }: HeaderProps) => {
  // CONTEXT
  const { state } = useApp();
  const { isMobile } = state;

  // REFS
  const ribbonRef = useRef<HTMLDivElement>(null);
  const yPos = useRef(0);
  const ribbonHeight = useRef(0);
  const linkAnimationState = useRef('idle');

  // HOOKS
  const { width, height } = useWindowSize();

  // FRAMER MOTION
  const { scrollY } = useScroll();
  const headerAnimationControls = useAnimation();
  const linkAnimationControls = useAnimation();
  const logoControls = useAnimationControls();

  const updateHeadingPos = useCallback((yPos: number) => {
    headerAnimationControls.set({ y: -(Math.min(yPos, ribbonHeight.current)) })
  }, [headerAnimationControls])

  useMotionValueEvent(scrollY, "change", (latest) => {

    const normalizedLatest = Math.max(latest, 0);

    updateHeadingPos(normalizedLatest);

    // BACK BUTTON
    if (yPos.current > normalizedLatest) {
      if (linkAnimationState.current === 'down') return;
      if (linkAnimationState.current === "up") linkAnimationControls.stop();
      linkAnimationState.current = 'down';
      linkAnimationControls.start({
        y: "0", opacity: 1, transition: {
          duration: 0.25, ease: "easeInOut", onComplete: () => { linkAnimationState.current = 'idle' }
        }
      });
    } else if (yPos.current < normalizedLatest) {
      if (linkAnimationState.current === 'up') return;
      if (linkAnimationState.current === "down") linkAnimationControls.stop();
      linkAnimationState.current = 'up';
      linkAnimationControls.start({
        y: "-100%", opacity: 0, transition: {
          duration: 0.25, ease: "easeInOut", onComplete: () => { linkAnimationState.current = 'idle' }
        }
      });
    }
    yPos.current = normalizedLatest;
  })

  useEffect(() => {
    if (!ribbonRef.current) return;

    ribbonHeight.current = Math.ceil(ribbonRef.current.getBoundingClientRect().height);
    updateHeadingPos(yPos.current);
  }, [width, height, updateHeadingPos])

  useEffect(() => {
    logoControls.start({
      rotateY: '360deg',
      transition: {
        duration: 3,
        delay: 0.8,
        ease: [0.5, .1, 0, 1]
      }
    })
  }, [logoControls]);

  return (
    <>
      {ribbon && <StyledSalesRibbon
        ref={ribbonRef}
        text={ribbon.text}
        link={{
          label: ribbon.link?.label || '',
          url: ribbon.link?.url || ''
        }}
      />}
      <HeaderWrapper
        initial={{ y: 0 }}
        animate={headerAnimationControls}
      >
        <HeaderContent>
          <ImageWrapper>
            <Link to="/">
              <MotionImage
                style={{ width: "100%", height: "100%" }}
                src="../../images/buffalo-logo.png"
                alt="Buffalo logo"
                animate={logoControls}
              />
            </Link>
          </ImageWrapper>
        </HeaderContent>
      </HeaderWrapper>

      {/* NOTE: Has to be outside of Header Wrapper for mix-blend-mode to work */}
      <BackButtonWrapper
        initial={{ y: 0 }}
        animate={headerAnimationControls}
      >
        <BackButtonContent
          initial={{ y: 0 }}
          animate={linkAnimationControls}>
          <AnimatedLink url={link.url}>
            {isMobile &&
              <Typography variant={"h9"}>
                <ArrowSpan>{"<"}</ArrowSpan>
                {link.labelMobile}
              </Typography>
            }
            {!isMobile &&
              <Typography variant={"h8"}>
                <ArrowSpan>{"<"}</ArrowSpan>
                {link.label}
              </Typography>
            }
          </AnimatedLink>
        </BackButtonContent>
      </BackButtonWrapper>
    </>
  );
};

export default Header;
