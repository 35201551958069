import { useCallback } from 'react';
import {
  useImageWorker,
  ImageWorkerActions,
} from '../context/ImageWorkerContext';

interface LoadImagesProps {
  id: string;
  images: string[];
}

export default function useImageLoader(): [{ id: string; images: string[] }[], (props: LoadImagesProps) => void] {
  const { state, dispatch } = useImageWorker();

  const loadImages = useCallback(
    ({ id, images }: LoadImagesProps) => {
      dispatch({ type: ImageWorkerActions.LOAD_IMAGES, payload: { id, images } })
    }, [dispatch]
  )

  return [state.images, loadImages];
}
