import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Theme, ThemeWrapperProps } from './ThemeWrapper.types'

const ThemeWrapperEl = styled.div.attrs((props: { theme: Theme }) => props)`
  background-image: url('/images/grain.png');
  background-color: ${(props) =>
    props.theme === Theme.LIGHT
      ? "var(--color-grey-light)"
      : 'transparent'};
  background-blend-mode: multiply, normal;
  color: ${(props) => props.theme === Theme.LIGHT ? "black" : 'white'};
`

export const ThemeWrapper = forwardRef<HTMLDivElement, ThemeWrapperProps>(({ className, children, theme = Theme.LIGHT, ...args }: ThemeWrapperProps, ref) => {
  return (
    <ThemeWrapperEl className={className} theme={theme} ref={ref} {...args}>
      {children}
    </ThemeWrapperEl>
  )
});

export default ThemeWrapper;

