import React from "react";

interface SpacerProps {
  size?: number;
}

const Spacer = ({ size = 1 }: SpacerProps) => {
  return <div style={{ height: `calc(${size} * 0.4rem)`, width: "100%" }} />;
};

export default Spacer;
